import { useModalitiesReport } from '@/queries/report'
import {
  BarElement,
  CategoryScale,
  ChartData,
  ChartDataset,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import colorbrewer from 'colorbrewer'
import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { ReportFilter } from 'shared'
import voca from 'voca'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)

export const ModalitiesChart = ({
  filterType,
  filterId,
  interval,
  periods,
}: {
  filterType: ReportFilter
  filterId: number | undefined
  interval: 'daily' | 'monthly' | 'yearly'
  periods: number
}) => {
  const { data: reportData } = useModalitiesReport({
    filterType,
    filterId,
    interval,
    periods,
  })

  const options: ChartOptions<'bar'> = {
    plugins: {
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        text: `${voca.titleCase(interval)} Modalities`,
        font: {
          weight: 400,
          size: 16,
        },
      },
      legend: {
        display: true,
        position: 'bottom',
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  const chartData: ChartData<'bar', number[]> = useMemo(() => {
    if (!Array.isArray(reportData)) {
      return { datasets: [], labels: [] } as ChartData<'bar', number[]>
    }

    const ticks = _.range(periods ?? 12)
      .map((t) =>
        interval === 'daily'
          ? moment().add(-t, 'days').format('YYYY-MM-DD')
          : interval === 'monthly'
          ? moment().add(-t, 'months').format('YYYY-MM')
          : interval === 'yearly'
          ? moment().add(-t, 'years').format('YYYY')
          : ''
      )
      .reverse()

    const modalityIds: Record<string, number> = {}
    for (const modality of _.uniq(reportData.map((i) => i.modalityCode))) {
      modalityIds[modality] = Object.keys(modalityIds).length
    }

    const byModality = _.groupBy(reportData, (i) => i.modalityCode)

    return {
      datasets:
        Object.keys(byModality)?.map((modality, i) => {
          const byPeriod = _.groupBy(byModality?.[modality] ?? [], 'period')
          const data = ticks.map((tick) => {
            const periodData = byPeriod?.[tick]
            return periodData?.[0]?.count ?? 0
          })
          return {
            label: modality,
            key: modality,
            data,
            backgroundColor: colorbrewer.Pastel1[9][i % 9],
          }
        }) ?? ([] as ChartDataset<'bar', number[]>[]),
      labels: ticks.map((tick) =>
        interval === 'daily'
          ? moment(tick).format('MMM DD')
          : interval === 'monthly'
          ? moment(tick).format("MMM 'YY")
          : interval === 'yearly'
          ? moment(tick).format('YYYY')
          : []
      ),
    }
  }, [reportData, periods, interval])

  return (
    <div className='w-full h-full overflow-y-auto'>
      <Bar options={options} data={chartData} />
    </div>
  )
}

export default ModalitiesChart
