import { fetchFromApi } from '@/lib/fetchFromApi'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'
import { ApiFacility } from 'shared'

export const useFetchFacilities = () => {
  return useQuery({
    queryKey: ['facilities'],
    queryFn: async (): Promise<ApiFacility[]> => {
      const res = await fetchFromApi('facility')
      if (!res.ok) {
        throw new Error('Failed to fetch facilities')
      }
      return await res.json()
    },
  })
}

export const useFetchFacility = (facilityId: number) => {
  return useQuery({
    queryKey: ['facility', facilityId],
    queryFn: async (): Promise<ApiFacility> => {
      if (isNaN(facilityId)) throw new Error('Facility ID is not a number')

      const res = await fetchFromApi(`facility/${facilityId}`)
      if (!res.ok) {
        throw new Error(`Failed to fetch facility ${facilityId}`)
      }
      return await res.json()
    },
  })
}

export const useCreateFacility = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (facility: Partial<ApiFacility>): Promise<ApiFacility> => {
      const res = await fetchFromApi('facility', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(facility),
      })
      if (!res.ok) {
        throw new Error('Failed to create facility')
      }
      return await res.json()
    },
    onSuccess: (facility: ApiFacility) => {
      queryClient.setQueryData(['facility', facility.facilityId], facility)
      queryClient.invalidateQueries({ queryKey: ['facilities'] })
    },
  })
}

export const useUpdateFacility = (facilityId: number) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (facility: Partial<ApiFacility>): Promise<ApiFacility> => {
      if (isNaN(facilityId)) throw new Error('Facility ID is not a number')

      const res = await fetchFromApi(`facility/${facilityId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(facility),
      })
      if (!res.ok) {
        throw new Error(`Failed to update facility ${facilityId}`)
      }
      return await res.json()
    },
    onMutate: (facility: Partial<ApiFacility>) => {
      const oldFacility = queryClient.getQueryData<ApiFacility>(['facility', facilityId])
      queryClient.setQueryData(['facility', facilityId], { ...oldFacility, ...facility })
    },
    onSuccess: (facility: ApiFacility) => {
      queryClient.setQueryData(['facility', facilityId], facility)
      queryClient.refetchQueries({ queryKey: ['facility', facilityId] })
      queryClient.invalidateQueries({ queryKey: ['facilities'] })
    },
  })
}

export const useCreateFacilityAlias = (facilityId: number) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (alias: string): Promise<void> => {
      if (isNaN(facilityId)) throw new Error('Facility ID is not a number')

      const res = await fetchFromApi(`facility/${facilityId}/alias`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ alias }),
      })
      if (!res.ok) {
        throw new Error(`Failed to create facility alias for facility ${facilityId}`)
      }
    },
    onMutate: (alias: string) => {
      const facility = queryClient.getQueryData<ApiFacility>(['facility', facilityId])
      queryClient.setQueryData(['facility', facilityId], {
        ...facility,
        aliases: [...(facility?.aliases ?? []), alias],
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['facility', facilityId] })
    },
  })
}

export const useDeleteFacilityAlias = (facilityId: number) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (alias: string): Promise<void> => {
      if (isNaN(facilityId)) throw new Error('Facility ID is not a number')

      const res = await fetchFromApi(`facility/${facilityId}/alias/${alias}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (!res.ok) {
        throw new Error(`Failed to delete facility alias ${alias} for facility ${facilityId}`)
      }
    },
    onMutate: (alias: string) => {
      const facility = queryClient.getQueryData<ApiFacility>(['facility', facilityId])
      queryClient.setQueryData(['facility', facilityId], {
        ...facility,
        aliases: _.without(facility?.aliases ?? [], alias),
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['facility', facilityId] })
    },
  })
}
