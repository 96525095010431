import { useModalitiesReport, useTopReferrersReport } from '@/queries/report'
import {
  BarElement,
  CategoryScale,
  ChartData,
  ChartDataset,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import colorbrewer from 'colorbrewer'
import _ from 'lodash'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { MonthlyModalities, TopReferrer } from 'shared'
import voca from 'voca'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const TopReferrersChart = ({
  numReferrers,
  interval,
  periods,
}: {
  numReferrers: number
  interval: 'daily' | 'monthly' | 'yearly'
  periods: number
}) => {
  const { data: topReferrers } = useTopReferrersReport({
    interval,
    periods,
    referrers: numReferrers,
  })

  const { data: byModality } = useModalitiesReport({
    filterType: 'by-physician',
    filterId: topReferrers?.map((r: TopReferrer) => r.referringPhysicianId).join(','),
    interval,
    periods,
  })

  const options: ChartOptions<'bar'> = {
    plugins: {
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        text: `${voca.titleCase(interval)} Top Referrers`,
        font: {
          weight: 400,
          size: 16,
        },
      },
      legend: {
        display: true,
        position: 'bottom',
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  const modalities = _.uniq(byModality?.map((i: MonthlyModalities) => i.modalityCode)).toSorted()

  const chartData = useMemo(() => {
    if (!Array.isArray(byModality)) {
      return { datasets: [], labels: [] } as ChartData<'bar', number[]>
    }

    const physicians = _.sortBy(topReferrers, (r: TopReferrer) => r.count).reverse()
    const counts = modalities.map((code) =>
      physicians.map((phys) => {
        return (
          _.filter(
            byModality,
            (i) => i.modalityCode === code && i.referringPhysicianId === phys.referringPhysicianId
          ).reduce((acc, i) => acc + i.count, 0) ?? 0
        )
      })
    )

    return {
      datasets:
        modalities.map((modality, i) => {
          return {
            key: modality,
            label: modalities[i],
            data: counts[i],
            backgroundColor: colorbrewer.Pastel1[9][i % 9],
          }
        }) ?? ([] as ChartDataset<'bar', number[]>[]),
      labels: physicians.map((phys) => voca.titleCase(phys.referringPhysicianName ?? 'N/A')),
    }
  }, [byModality, modalities, topReferrers])

  return (
    <div className='w-full h-full overflow-y-auto'>
      <Bar options={options} data={chartData as ChartData<'bar', number[], unknown>} />
    </div>
  )
}
