import { Button, IconButton } from '@/components/ui/Button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/Card'
import { X } from 'lucide-react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import voca from 'voca'

interface SidePanelProps {
  label: string
  description?: string | React.ReactNode
  children: React.ReactNode
  canEdit?: boolean
  onEdit?: () => void
  onCancel?: () => void
  className?: string
}

export const SidePanel: React.FC<SidePanelProps> = ({
  label,
  description,
  canEdit,
  onEdit,
  children,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const parent = location.pathname.split('/').slice(0, -1).join('/')

  return (
    <Card className={`w-full overflow-y-auto min-h-full flex flex-col`}>
      <CardHeader className='shrink mb-4'>
        <div className='flex flex-row items-center justify-around'>
          <div className='flex-1'>
            <CardTitle>{voca.titleCase(label)}</CardTitle>
            {description && <CardDescription>{description}</CardDescription>}
          </div>

          <div className='shrink flex flex-row items-center'>
            {canEdit === true && (
              <Button className='px-4' variant='outline' title='Edit' onClick={onEdit}>
                Edit
              </Button>
            )}

            <IconButton
              className='px-4 text-neutral-800'
              icon={<X />}
              onClick={() => navigate(parent)}
            />
          </div>
        </div>
      </CardHeader>

      <CardContent className='flex-1 flex flex-col'>{children}</CardContent>
    </Card>
  )
}
