import { Button, IconButton } from '@/components/ui/Button'
import { FileInput } from '@/components/ui/Input'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/Table'
import { humanReadableTimeOrDate } from '@/lib/dates'
import {
  useDeleteStudyAttachment,
  useDownloadAttachment,
  useFetchStudyAttachments,
  useGetNewAttachmentURL,
  usePostNewAttachment,
} from '@/queries/studies'
import { useAuthStore } from '@/state/authState'
import _ from 'lodash'
import { DownloadIcon, TrashIcon } from 'lucide-react'
import { useState } from 'react'

export const AttachmentsTable = ({ worklistId }: { worklistId: number }) => {
  const { isLoading, data: files } = useFetchStudyAttachments(worklistId)
  const { mutateAsync: getAttachmentUrl } = useGetNewAttachmentURL(worklistId)
  const { mutateAsync: postAttachment } = usePostNewAttachment(worklistId)
  const { mutateAsync: downloadAttachment } = useDownloadAttachment(worklistId)
  const { mutateAsync: deleteAttachment } = useDeleteStudyAttachment(worklistId)
  const [attachment, setAttachment] = useState<File | null>(null)
  const role = useAuthStore((u) => u.role)

  return (
    <div className='flex flex-col gap-4'>
      <Table className='w-full'>
        <TableHeader>
          <TableRow>
            <TableHead>Date</TableHead>
            <TableHead>Filename</TableHead>
            <TableHead className='text-right'>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={3}>Loading...</TableCell>
            </TableRow>
          ) : !!files && files.length > 0 ? (
            files.map((f, i) => (
              <TableRow key={f.fileId}>
                <TableCell className='max-w-[55px] text-ellipsis whitespace-nowrap overflow-hidden'>
                  {humanReadableTimeOrDate(f.uploadDatetime!)}
                </TableCell>
                <TableCell className='max-w-[150px] text-ellipsis whitespace-nowrap overflow-hidden'>
                  {_.last(f.url?.split('/')) ?? 'N/A'}
                </TableCell>
                <TableCell className='text-right whitespace-nowrap'>
                  <IconButton
                    icon={<DownloadIcon className='w-4 h-4' />}
                    onClick={async () => {
                      const url = await downloadAttachment(f.fileId)
                      window.open(url!, '_blank')
                    }}
                  />
                  <IconButton
                    icon={<TrashIcon className='w-4 h-4' />}
                    onClick={() => deleteAttachment(f.fileId)}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} className='text-center italic'>
                No attachments
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {role !== 'physician-group' && (
        <>
          <FileInput selectedFile={attachment} onChange={setAttachment} />
          <Button
            variant='outline'
            disabled={!attachment}
            onClick={async () => {
              if (!attachment) return
              const url = await getAttachmentUrl(attachment!.name)
              await fetch(url, {
                method: 'PUT',
                body: attachment,
              })
              await postAttachment(url)
              setAttachment(null)
            }}>
            Upload Attachment
          </Button>
        </>
      )}
    </div>
  )
}
