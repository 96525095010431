import { cn } from '@/lib/utils'
import React from 'react'

export const PanelGroup = ({
  parent,
  children,
}: {
  parent: React.ReactNode
  children?: React.ReactNode
}) => {
  return (
    <div className='relative h-full w-full overflow-x-hidden'>
      <div
        className={cn(
          'transition-all duration-300 ease-in-out h-full',
          children ? 'md:pr-[600px]' : ''
        )}>
        {parent}
      </div>

      {children && (
        <div className='fixed top-0 right-0 h-screen w-[600px] bg-white shadow-xl z-50'>
          {children}
        </div>
      )}
    </div>
  )
}

export default PanelGroup
