import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/Table'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ApiFacility } from 'shared'
import voca from 'voca'

interface FacilitiesTableProps {
  facilities: ApiFacility[] | undefined
}

export const FacilitiesTable: React.FC<FacilitiesTableProps> = ({ facilities }) => {
  const navigate = useNavigate()

  if (!facilities || facilities.length === 0) {
    return <p className='text-center mt-4'>No facilities to display</p>
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead>City</TableHead>
          <TableHead>State</TableHead>
          <TableHead>Phone Number</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {facilities.map((facility) => (
          <TableRow
            key={facility.facilityId}
            onClick={() => {
              navigate(`/facilities/${facility.facilityId}`)
            }}>
            <TableCell>{voca.titleCase(facility.name!)}</TableCell>
            <TableCell>{facility.city}</TableCell>
            <TableCell>{facility.state}</TableCell>
            <TableCell>{facility.phone}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
