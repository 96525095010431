import { Link } from 'react-router-dom'
import { ApiPhysicianGroup } from 'shared'
import voca from 'voca'
import { PhysiciansTable } from './PhysiciansTable'

export const PhysicianGroupTable = ({
  groups,
  selectedPhysicianID,
  selectedGroupID,
}: {
  groups: ApiPhysicianGroup[]
  selectedPhysicianID?: number
  selectedGroupID?: number
}) => {
  if (groups.length === 0) {
    return <div className='text-lg italic text-center'>No physicians to display</div>
  }

  return (
    <div className='flex flex-col w-full gap-4'>
      {groups.map((g, i) => (
        <PhysiciansTable
          key={g.physicianGroupId}
          label={
            <Link to={`/groups/${g.physicianGroupId}`}>
              {voca.titleCase(g.groupName ?? 'Unknown')}
            </Link>
          }
          physicians={g.physicians ?? []}
          selectedPhysicianID={selectedPhysicianID}
          selectedGroupID={selectedGroupID}
          showHeader={i === 0}
        />
      ))}
    </div>
  )
}
