import { Button } from '@/components/ui/Button'
import { Input } from '@/components/ui/Input'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/Popover'
import { cn } from '@/lib/utils'
import { useSearchPatients } from '@/queries/patient'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { ApiPatient } from 'shared'
import voca from 'voca'

export function PatientsComboBox(props: {
  patient: ApiPatient | null
  originalPatient?: ApiPatient
  onChange?: (patient: Partial<ApiPatient>) => void
  className?: string
}) {
  const { patient } = props
  const [isOpen, setOpen] = useState(false)

  const setIdFromPatient = (p: ApiPatient) => {
    props.onChange?.(p)
    setOpen(false)
  }

  let patientName = patient?.name
  if (patient?.firstName && patient?.lastName) {
    patientName = `${patient.lastName}, ${patient.firstName}`
  }

  return (
    <Popover open={isOpen} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant='outline' className={cn('justify-start h-8 p-2', props.className)}>
          {patientName ? voca.titleCase(patientName) : <div className='italic'>+ Assign</div>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[340px] p-0 mr-2 shadow-lg' align='start'>
        <PatientsList
          setSelectedPatient={setIdFromPatient}
          patientId={patient?.patientId ?? -1}
          originalPatient={props.originalPatient}
        />
      </PopoverContent>
    </Popover>
  )
}

function PatientsList({
  setSelectedPatient,
  patientId,
  originalPatient,
}: {
  setSelectedPatient: (status: ApiPatient) => void
  patientId?: number
  originalPatient?: ApiPatient
}) {
  const [searchText, setSearchText] = useState('')
  const { data } = useSearchPatients({ searchText })
  const patients = useMemo(() => _.flatten(data?.pages), [data])

  return (
    <div>
      <Input
        placeholder='Search...'
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
      />
      {originalPatient && (
        <div className='overflow-hidden w-full px-3 py-1 text-xs text-neutral-600'>
          Original: {voca.titleCase(originalPatient.name ?? 'N/A')}
        </div>
      )}
      <div className='max-h-[300px] overflow-y-auto'>
        {patients?.map((p) => {
          let { firstName, lastName, name } = p
          if (firstName && lastName) name = `${lastName}, ${firstName}`
          return (
            <div
              className='hover:bg-neutral-200 cursor-pointer '
              onClick={() => setSelectedPatient(p)}>
              <div
                key={p.patientId}
                className={cn(
                  'overflow-hidden w-full px-2 my-0.5 text-sm text-neutral-600',
                  patientId === p.patientId && 'font-semibold'
                )}>
                {voca.titleCase(name ?? 'N/A')}

                <div className='mx-2 text-xs text-neutral-400'>MRN: {p.mrn}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
