import { LoadingSpinner } from '@/components/LoadingSpinner'
import { PanelGroup } from '@/components/PanelGroup'
import StudiesTable from '@/components/StudiesPage/StudiesTable'
import { StudyDetailPanel } from '@/components/StudiesPage/StudyDetailPanel'
import { Input } from '@/components/ui/Input'
import { cn } from '@/lib/utils'
import { useFetchMoreStudiesIfPageUnfilled, useFetchStudies } from '@/queries/studies'
import { useQueryClient } from '@tanstack/react-query'
import dateParser from 'any-date-parser'
import _ from 'lodash'
import type { ComponentType } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router-dom'

const parseIntOrNull = (str?: string) => {
  const result = str ? parseInt(str, 10) : null
  return result && !isNaN(result) ? result : null
}

const InfiniteScrollComponent = InfiniteScroll as unknown as ComponentType<any>

const StudiesView = () => {
  const params = useParams()
  const selectedWorklistId = parseIntOrNull(params.worklistId)
  const queryClient = useQueryClient()
  const scrollRef = useRef<HTMLDivElement>(null)
  const selectedStudyRowRef = useRef<HTMLTableRowElement>(null)
  const [searchText, setSearchText] = useState('')

  // NOTE: searchText is the raw search text, searchQuery is the formatted
  //       search text.  This is needed if searching by date.
  const searchQuery = useMemo(() => {
    const dateStr = dateParser.attempt(searchText)
    const { month, day } = dateStr
    if (month && day) {
      return `${_.padStart(month, 2, '0')}-${_.padStart(day, 2, '0')}`
    }
    return searchText
  }, [searchText])

  const query = useFetchStudies(() => searchQuery)
  useFetchMoreStudiesIfPageUnfilled(
    () => query,
    () => scrollRef
  )

  // Flatten Pages from Query
  const studies = useMemo(() => _.flatten(query.data?.pages), [query.data])

  const updateSearchText = (text: string) => {
    setSearchText(text)
    queryClient.removeQueries({ queryKey: ['studies'] })
  }

  useEffect(() => {
    if (selectedStudyRowRef.current) {
      selectedStudyRowRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [selectedStudyRowRef])

  return (
    <div className='flex flex-col h-full w-full'>
      <Input
        value={searchText}
        onChange={(e) => updateSearchText(e.target.value)}
        autoCapitalize='false'
        placeholder='Search Study'
        className='mt-6 mb-4 border-neutral-100 shadow-sm'
      />

      <PanelGroup
        parent={
          <div id='scrollableStudiesDiv' className='w-full h-full overflow-y-auto' ref={scrollRef}>
            <InfiniteScrollComponent
              className={cn('flex flex-col w-full', selectedWorklistId ? 'blur-sm md:blur-0' : '')}
              dataLength={studies.length}
              next={query.fetchNextPage}
              hasMore={query.hasNextPage ?? false}
              refreshFunction={query.refetch}
              pullDownToRefresh={true}
              pullDownToRefreshThreshold={50}
              releaseToRefreshContent={<h3 style={{ textAlign: 'center' }}>Release to refresh</h3>}
              scrollableTarget='scrollableStudiesDiv'
              loader={
                query.isLoading && (
                  <div className='flex justify-center w-full'>
                    <LoadingSpinner />
                  </div>
                )
              }>
              {query.isLoading ? (
                <div className='flex justify-center w-full'>
                  <LoadingSpinner />
                </div>
              ) : (
                <StudiesTable
                  studies={studies ?? []}
                  selectedStudyId={selectedWorklistId!}
                  selectedStudyRowRef={selectedStudyRowRef}
                />
              )}
            </InfiniteScrollComponent>
          </div>
        }>
        {!!selectedWorklistId && !isNaN(selectedWorklistId) && <StudyDetailPanel />}
      </PanelGroup>
    </div>
  )
}

export default StudiesView
