import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/Table'
import { humanReadableDate } from '@/lib/dates'
import { useNavigate } from 'react-router-dom'
import { ApiStudy } from 'shared'
import voca from 'voca'

export const PatientStudies = ({
  studies,
  className,
}: {
  studies: ApiStudy[]
  className?: string
}) => {
  const navigate = useNavigate()

  if (!studies || studies.length === 0) {
    return <div>No studies</div>
  }

  return (
    <div className={className}>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Date</TableHead>
            <TableHead>M.</TableHead>
            <TableHead>Procedure</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {studies.map((study) => (
            <TableRow key={study.studyUid} onClick={() => navigate(`/studies/${study.worklistId}`)}>
              <TableCell>
                {study.studyDatetime ? humanReadableDate(study.studyDatetime) : 'N/A'}
              </TableCell>
              <TableCell>{study.modalityCode}</TableCell>
              <TableCell>{voca.capitalize(study.modalityProcedure ?? '')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default PatientStudies
