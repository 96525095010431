import { Button } from '@/components/ui/Button'
import { Input } from '@/components/ui/Input'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/Popover'
import { useFetchPhysicians } from '@/queries/physician'
import _ from 'lodash'
import { useState } from 'react'
import { ApiPhysician } from 'shared'
import voca from 'voca'
import { cn } from '../../lib/utils'

export function PhysicianComboBox(props: {
  physician: ApiPhysician
  originalPhysName?: string
  showOriginalPhysName?: boolean
  placeholder?: string
  className?: string
  onChange?: (phys: ApiPhysician) => void
}) {
  const { physician, placeholder } = props
  const [open, setOpen] = useState(false)

  const changeSelected = (phys: ApiPhysician) => {
    props.onChange?.(phys)
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant='outline' className={cn('justify-start h-8 p-2 w-full', props.className)}>
          {physician ? (
            <div>{voca.titleCase(physician.name ?? props.originalPhysName)}</div>
          ) : (
            <div className='italic'>{placeholder ?? 'Select Physician'}</div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[380px] p-0 mr-2 shadow-lg' align='start'>
        <PhysList
          setSelectedPhysician={changeSelected}
          physId={physician?.physicianId}
          originalPhysName={props.originalPhysName}
          showOriginalPhysName={props.showOriginalPhysName}
        />
      </PopoverContent>
    </Popover>
  )
}

function PhysList({
  setSelectedPhysician,
  physId,
  originalPhysName,
  showOriginalPhysName,
}: {
  setSelectedPhysician: (status: ApiPhysician) => void
  physId?: number
  originalPhysName?: string
  showOriginalPhysName?: boolean
}) {
  const [searchText, setSearchText] = useState('')
  let { isLoading, data: physicians } = useFetchPhysicians()

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!_.isArray(physicians) || physicians.length === 0) {
    return <div>No physicians to display</div>
  }

  if (searchText) {
    physicians = physicians.filter((phys: ApiPhysician) =>
      _.every(
        searchText.toLowerCase().split(' '),
        (part) =>
          phys.name?.toLowerCase()?.includes(part) ||
          phys.physicianGroup?.toLowerCase()?.includes(part)
      )
    )
  } else {
    // TODO: Show the original physician name if it's not in the list
    physicians = _.slice(physicians, 0, 5)
  }

  physicians = physicians.slice(0, 20)

  return (
    <div className='px-3'>
      <Input
        placeholder='Filter...'
        className='bottom-1'
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
      />
      {showOriginalPhysName && (
        <div className='overflow-hidden w-full my-1 text-xs text-neutral-600'>
          Original: "{originalPhysName}"
        </div>
      )}
      <div className='max-h-[300px] overflow-y-auto'>
        {physicians.map((phys) => {
          return (
            <div
              key={phys.physicianId}
              className='py-0.5 hover:bg-neutral-200 cursor-pointer '
              onClick={() => setSelectedPhysician(phys)}>
              <div
                className={cn(
                  'overflow-hidden w-full text-sm text-neutral-600',
                  physId === phys.physicianId && 'font-semibold text-neutral-950'
                )}>
                {`${voca.titleCase(phys!.name)} (${phys?.studiesCount ?? 0})`}
              </div>
              <div className='px-2 text-xs text-neutral-400'>
                {voca.titleCase(phys?.physicianGroup ?? 'No Physician Group')}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
