import LoadingScreen from '@/components/LoadingScreen'
import Navbar from '@/components/Navbar'
import { Toaster } from '@/components/ui/Sonner'
import { auth } from '@/firebase'
import FacilitiesPage from '@/pages/FacilitiesPage'
import Login from '@/pages/Login'
import NotFound from '@/pages/NotFound'
import PatientsPage from '@/pages/PatientsPage'
import PhysiciansPage from '@/pages/PhysiciansPage'
import ReportsPage from '@/pages/ReportsPage'
import StudiesPage from '@/pages/StudiesPage'
import UsersPage from '@/pages/UsersPage'
import { useAuthStore } from '@/state/authState'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { onAuthStateChanged } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom'

const showReactQueryDevtools =
  !/^prod/i.test(process.env.NODE_ENV ?? 'dev') &&
  process.env.REACT_APP_SHOW_REACT_QUERY_DEVTOOLS === 'true'

const authedRouter = createBrowserRouter([
  {
    path: '/',
    element: <AuthedLayout />,
    children: [
      {
        path: '',
        element: <Navigate to='/studies' replace />,
      },
      { path: 'studies', element: <StudiesPage /> },
      { path: 'studies/:worklistId', element: <StudiesPage /> },
      { path: 'patients', element: <PatientsPage /> },
      { path: 'patients/:patientId', element: <PatientsPage /> },
      { path: 'physicians', element: <PhysiciansPage category='physician' /> },
      { path: 'physicians/:id', element: <PhysiciansPage category='physician' /> },
      { path: 'groups', element: <PhysiciansPage category='group' /> },
      { path: 'groups/:id', element: <PhysiciansPage category='group' /> },
      { path: 'facilities', element: <FacilitiesPage /> },
      { path: 'facilities/:id', element: <FacilitiesPage /> },
      { path: 'reports', element: <ReportsPage /> },
      { path: 'users', element: <UsersPage /> },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
])

const guestRouter = createBrowserRouter([
  {
    path: '/',
    element: <GuestLayout />,
    children: [
      {
        path: '',
        element: <Navigate to='/login' replace />,
      },
      { path: 'login', element: <Login /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to='/login' replace />,
  },
])

const queryClient = new QueryClient()

function App() {
  const isAuthed = useAuthStore((s) => !!s.token)
  const setUser = useAuthStore((s) => s.setUser)
  const clearUser = useAuthStore((s) => s.clearUser)
  const [authInitialized, setAuthInitialized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const router = isAuthed ? authedRouter : guestRouter

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoading(true)
      if (user) {
        const token = await user.getIdToken()
        const role = 'admin' as const
        setUser(user.email!, role, token, user.refreshToken)
        router.navigate('/studies', { replace: true })
      } else {
        clearUser()
        router.navigate('/login', { replace: true })
      }
      setAuthInitialized(true)
      setIsLoading(false)
    })

    return () => unsubscribe()
  }, [setUser, clearUser, router])

  if (!authInitialized || isLoading) {
    return <LoadingScreen />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      {showReactQueryDevtools && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}

function GuestLayout() {
  return (
    <div className='flex justify-center py-8 h-full'>
      <div className='max-w-[400px] h-full'>
        <Outlet />
      </div>
      <Toaster className='z-50' />
    </div>
  )
}

function AuthedLayout() {
  return (
    <div className='flex h-full min-w-[360px] flex-col'>
      <Navbar />
      <div className='mx-auto w-full grow px-6 py-4 h-full overflow-y-hidden flex max-w-[1600px]'>
        <Outlet />
        <Toaster className='z-50' />
      </div>
    </div>
  )
}

export default App
