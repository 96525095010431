import { Card, CardDescription, CardHeader, CardTitle } from '@/components/ui/Card'
import { useCreatePhysician } from '@/queries/physician'
import { useFetchPhysicianGroup } from '@/queries/physicianGroup'
import { Loader2 } from 'lucide-react'
import { Link, useSearchParams } from 'react-router-dom'
import { ApiPhysician } from 'shared'
import { EditPhysicianInfo } from './EditPhysicianInfo'

export const NewPhysician = ({
  onSave,
  onCancel,
}: {
  onSave: (physician: ApiPhysician) => void
  onCancel: () => void
}) => {
  const [searchParams] = useSearchParams()
  const groupId = searchParams.get('groupId') || undefined
  const { isLoading, data: group } = useFetchPhysicianGroup(parseInt(`${groupId}`))
  const { mutateAsync: createPhysician } = useCreatePhysician()

  if (isLoading) {
    return <Loader2 className='animate-spin' />
  }

  return (
    <Card className='h-full flex flex-col'>
      <CardHeader className='flex flex-row justify-between'>
        <div>
          <CardTitle>New Physician</CardTitle>
          {group?.groupName && (
            <CardDescription className='mt-1 text-indigo-700 hover:text-indigo-500 font-medium'>
              <Link to={`/groups/${group?.physicianGroupId}`}>{group?.groupName}</Link>
            </CardDescription>
          )}
        </div>
      </CardHeader>

      <EditPhysicianInfo
        className='mt-4'
        physician={{
          groupId: parseInt(`${groupId}`),
        }}
        onSave={async (physician) => {
          const data = await createPhysician(physician)
          onSave(data)
        }}
        onCancel={onCancel}
      />
    </Card>
  )
}
