import { SidePanel } from '@/components/SidePanel'
import { useCreateFacility } from '@/queries/facility'
import { ApiFacility } from 'shared'
import { toast } from 'sonner'
import { EditFacilityForm } from './EditFacilityForm'

export const NewFacilityView = ({
  onSave,
  onCancel,
}: {
  onSave: (facility: ApiFacility) => void
  onCancel: () => void
}) => {
  const { mutateAsync: createFacility } = useCreateFacility()

  const handleSave = async (facility: Partial<ApiFacility>) => {
    if (!facility.name) {
      toast('Facility name is required')
      return
    }

    if (!facility.email) {
      toast('Email is required')
      return
    }

    if (!facility.phone) {
      toast('Phone is required')
      return
    }

    if (!facility.address || !facility.city || !facility.state || !facility.zip) {
      toast('Address is required')
      return
    }

    const data = await createFacility(facility)
    onSave(data)
  }

  return (
    <SidePanel label='New Facility' onCancel={onCancel}>
      <EditFacilityForm className='mt-4' onSave={handleSave} onCancel={onCancel} />
    </SidePanel>
  )
}
