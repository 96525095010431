import { Select, SelectContent, SelectTrigger, SelectValue } from '@/components/ui/Select'
import { useFetchPhysicianGroups } from '@/queries/physicianGroup'
import { SelectItem } from '@radix-ui/react-select'
import { useMemo } from 'react'
import { ApiPhysicianGroup } from 'shared'
import voca from 'voca'

export const PhysicianGroupSelect = ({
  selectedGroupID,
  onChange,
  placeholder,
}: {
  selectedGroupID: number | undefined
  onChange: (group: ApiPhysicianGroup | undefined) => void
  placeholder?: string
}) => {
  const { isLoading, data: groups } = useFetchPhysicianGroups()

  const selectedGroup = useMemo(() => {
    return selectedGroupID ? groups?.find((g) => g.physicianGroupId === selectedGroupID) : undefined
  }, [selectedGroupID, groups])

  if (isLoading) {
    return <div>Loading...</div>
  }

  const sortedGroups = groups?.sort((g1, g2) => g1.groupName!.localeCompare(g2.groupName!))

  return (
    <Select
      value={`${selectedGroupID}` ?? undefined}
      onValueChange={(id: any) => onChange(groups?.find((g) => g.physicianGroupId === parseInt(id)))}>
      <SelectTrigger>
        <SelectValue className='bg-red-100' placeholder='Select Group...'>
          {selectedGroup ? (
            voca.titleCase(selectedGroup.groupName!)
          ) : (
            <div className='italic'>{placeholder ?? 'Select Physician Group'}</div>
          )}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {sortedGroups?.map((g) => (
          <SelectItem key={g.physicianGroupId} value={`${g.physicianGroupId}`}>
            {voca.titleCase(g.groupName ?? 'Unknown')}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
