import { PatientsTable } from '@/components/PatientsPage/PatientsTable'
import { SidePanel } from '@/components/SidePanel'
import { ListField, ListFieldStacked } from '@/components/ui/List'
import { TabBrowser } from '@/components/ui/TabView'
import { stringFracDist } from '@/lib/strings'
import { useFetchPhysician, useFetchPhysicians, useUpdatePhysician } from '@/queries/physician'
import { useAuthStore } from '@/state/authState'
import * as levvenshtein from 'fast-levenshtein'
import _ from 'lodash'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ApiPhysician } from 'shared'
import { EditPhysicianInfo } from './EditPhysicianInfo'
import { PhysStudiesTable } from './PhysStudiesTable'

export const PhysicianDetail = ({ physicianId }: { physicianId: number }) => {
  const isAdmin = useAuthStore((state) => state.role === 'admin')
  const isFacility = useAuthStore((state) => state.role === 'facility')
  const { isLoading, data: physician, error } = useFetchPhysician(physicianId)
  const [isEditing, setIsEditing] = useState(false)
  const { mutateAsync: updatePhysician } = useUpdatePhysician(physicianId)
  const [currentTab, setCurrentTab] = useState('patients')
  const hasAddress = physician?.address && physician?.city && physician?.state && physician?.zip

  if (isLoading) {
    return (
      <div className='flex justify-center mt-4 h-full'>
        <Loader2 className='animate-spin mr-4' /> Loading Physician...
      </div>
    )
  }

  if (error || !physician) {
    return (
      <div className='text-red-600 p-4'>
        <h2 className='text-xl font-bold mb-2'>Error Loading Physician</h2>
        <p>{error?.message || 'Physician not found'}</p>
      </div>
    )
  }

  return (
    <SidePanel
      label={physician?.name ?? 'Physician Details'}
      description={
        physician?.physicianGroup ? (
          <Link
            to={`/groups/${physician.physicianGroupId}`}
            className='text-indigo-700 hover:text-indigo-500 font-medium'>
            {physician.physicianGroup}
          </Link>
        ) : (
          'No Physician Group'
        )
      }
      canEdit={(isAdmin || isFacility) && !isEditing}
      onEdit={() => setIsEditing(true)}>
      {!isEditing ? (
        <div className='flex-1 flex flex-col overflow-hidden'>
          <div className='shrink'>
            <ListField label='Email Address'>{physician.email ?? 'N/A'}</ListField>
            <ListField label='Phone Number'>{physician.phone ?? 'N/A'}</ListField>
            <ListField label='Fax Number'>{physician.fax ?? 'N/A'}</ListField>
            {hasAddress && (
              <ListFieldStacked label='Address'>
                <div>{physician?.address}</div>
                {!!physician?.address2 && <div>{physician?.address2}</div>}
                <div>
                  {physician?.city}, {physician?.state} {physician?.zip}
                </div>
              </ListFieldStacked>
            )}
          </div>

          <TabBrowser
            className='my-4'
            tabs={['patients', 'referrals']}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />

          <div className='mx-1 flex-1 overflow-y-auto'>
            {currentTab === 'patients' ? (
              <PatientsTable patients={physician?.patients ?? []} />
            ) : (
              currentTab === 'referrals' && <PhysStudiesTable studies={physician?.studies ?? []} />
            )}
          </div>

          <div className='shrink mt-16'>
            <SimilarNames physician={physician} />
          </div>
        </div>
      ) : (
        <EditPhysicianInfo
          physician={physician}
          onSave={async (params) => {
            await updatePhysician(params)
            setIsEditing(false)
          }}
          onCancel={() => setIsEditing(false)}
        />
      )}
    </SidePanel>
  )
}

const SimilarNames = ({ physician }: { physician: Partial<ApiPhysician> }) => {
  const { data: physicians } = useFetchPhysicians()

  if (!physicians) return null

  const similar = physicians
    .filter(
      (p) =>
        p.physicianId !== physician.physicianId &&
        stringFracDist(p?.name ?? '', physician.name ?? '') <= 0.35
    )
    .toSorted(
      (a, b) =>
        levvenshtein.get(a.name, physician.name ?? '') -
        levvenshtein.get(b.name, physician.name ?? '')
    )

  if (!_.isArray(similar) || similar.length === 0) {
    return null
  }

  return (
    <div>
      <h3 className='mb-1 text-lg font-semibold text-neutral-500'>Physicians with Similar Names:</h3>

      <ul>
        {similar?.map((i) => (
          <li key={i.physicianId} className='pl-3 text-sm'>
            <Link
              to={`/physicians/${i.physicianId}`}
              className='text-indigo-500 hover:text-indigo-700 hover:font-medium'>
              {i.name} ({i.studiesCount})
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
