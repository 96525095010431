export type UserRole = 'admin' | 'facility' | 'physician-group' | 'radiologist'

export interface ApiUser {
  userId?: string
  name: string
  email: string
  role: UserRole
  // TODO: Rename these to facilityId and physicianGroupId
  facility?: number // * for any facility
  physicianGroup?: number // * for any group
}

export interface ApiStudy {
  studyUid: string
  worklistId: number
  status: string | null

  bodyPart: string | null
  modalityId?: number | null
  modalityCode: string | null
  modalityProcedure: string | null

  requestedDatetime: Date | null
  completedDatetime: Date | null
  receivedDatetime: Date | null
  studyDatetime: Date | null

  priority: string | null
  priorityId?: number | null

  reason: string | null
  diagnosis: string | null

  documentsCount: number | null
  messagesCount: number | null
  seriesCount: number | null
  imagesCount: number | null

  patientId: number | null
  patientName: string | null
  patientMrn: string | null
  patient?: ApiPatient

  facilityId: number | null
  facilityName?: string | null

  branchId: number | null

  referringPhysicianId: number | null
  referringPhysicianName?: string | null
  referringPhysician?: ApiPhysician

  readPhysicianId: number | null
  readPhysicianName?: string | null
  readPhysician?: ApiPhysician

  radiologyReportId: number | null
  reportUrl?: string | null
  report?: ApiReport

  dicoms?: ApiDicomSeries[]
  files?: ApiFile[]
  memos?: ApiMessage[]
}

export interface ApiMessage {
  messageId: number
  message: string | null
  read: boolean | null
  fromRad?: boolean | null
  createdBy?: string | null
  createdByRole?: string | null
  createdDatetime?: Date | null
}

export interface ApiFile {
  fileId: number
  url: string | null
  uploadDatetime?: Date | null
}

export interface ApiReport {
  reportId: number
  pdfUrl: string | null
  preliminaryReportPdfUrl: string | null
  reportHistory: string | null
  reportImpression: string | null
  reportComparison: string | null
  reportFindings: string | null
  reportFullText: string | null
  reportSentDatetime?: Date | null
  faxSentDatetime?: Date | null
  faxNumber?: string | null
  faxTransactionNumber?: string | null
  faxTotalPages?: number | null
}

export interface ApiDicomStudy {
  studyUid: string
  patientName: string | null
  studyDescription: string | null
  series: ApiDicomSeries[]
}

export interface ApiDicomSeries {
  seriesUid: string | null
  seriesNumber: number | null
  seriesDescription: string | null
  seriesDatetime: Date | null
  modality: string | null
  sops: ApiDicomSop[]
}

export interface ApiDicomSop {
  sopUid: string | null
  sopClassUid: string | null
  instanceNumber: number | null
  imageId: string | null
  imageType: string | null
  modality: string | null
  url: string | null
}

export interface ApiPatient {
  patientId: number | null
  name: string | null
  firstName: string | null
  lastName: string | null
  mrn: string | null
  birthDate: string | null
  gender: string | null
  phone: string | null
  email: string | null
  studies?: ApiStudy[]
  studiesCount?: number | null
}

export interface ApiPhysician {
  physicianId: number
  physicianGroupId?: number | null
  physicianGroup?: string | null
  facilityId?: number | null
  name: string
  firstName: string | null
  lastName: string | null
  address: string | null
  address2: string | null
  city: string | null
  state: string | null
  zip: string | null
  email: string | null
  phone: string | null
  fax: string | null
  studiesCount?: number | null
  lastStudyReferralDate?: Date | null
  groupId?: number | null
  group?: ApiPhysicianGroup
  studies?: ApiStudy[]
  patients?: ApiPatient[]
}

export interface ApiPhysicianGroup {
  physicianGroupId: number
  groupName: string | null
  facilityId: number | null
  facility?: string | null
  address: string | null
  address2: string | null
  city: string | null
  state: string | null
  zip: string | null
  email: string | null
  phone: string | null
  fax: string | null
  physicians?: ApiPhysician[]
}

export interface ApiFacility {
  facilityId: number
  name: string | null
  email: string | null
  phone: string | null
  address: string | null
  address2: string | null
  city: string | null
  state: string | null
  zip: string | null
  aliases?: string[]
}

export interface ApiStudiesRequest {
  searchText?: string
  previousPageLastDate?: string
  nextPageFirstDate?: string
}

export interface ApiPatientsRequest {
  searchText?: string
  previousPageLastToken?: string
  nextPageFirstToken?: string
}

export type ReportType =
  | 'by-modality'
  | 'by-physician'
  | 'by-physician-group'
  | 'by-radiologist'
  | 'top-referrers'
export type ReportFilter = 'overall' | 'by-physician' | 'by-physician-group' | 'by-radiologist'
export type ReportInterval = 'daily' | 'monthly' | 'yearly'

export interface MonthlyModalities {
  period: string
  modalityCode: string
  count: number
  referringPhysicianId?: number
}

export interface TopReferrer {
  month: string
  referringPhysicianId: number
  count: number
  referringPhysicianName?: string
}

export function toQueryString(params: Record<string, any>): string {
  return Object.keys(params)
    .filter((key) => !!params[key])
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&')
}

export const parseIntOrUndefined = (str?: string) => {
  const result = str ? parseInt(str, 10) : undefined
  return result && !isNaN(result) ? result : undefined
}
