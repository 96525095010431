import { LoadingSpinner } from '@/components/LoadingSpinner'
import { Button } from '@/components/ui/Button'
import { useFetchUsers } from '@/queries/user'
import { useEditUserState } from '@/state/editUserState'
import _ from 'lodash'
import React from 'react'
import { EditUserRow } from '../components/UsersPage/EditUserRow'
import { UserRow } from '../components/UsersPage/UserRow'

const UsersPage: React.FC = () => {
  const { data, error, isLoading } = useFetchUsers()
  const editUserId = useEditUserState((state) => state.editUserId)
  const clearEditUser = useEditUserState((state) => state.clearEditUser)
  const isNewUser = useEditUserState((state) => state.isNewUser)
  const createNewUser = useEditUserState((state) => state.createNewUser)

  const users = _.orderBy(data, 'name', 'asc')

  if (isLoading) {
    return (
      <div className='flex justify-center w-full mt-16'>
        <LoadingSpinner />
      </div>
    )
  }
  if (error) return <div>Error: {error.message}</div>

  return (
    <div className='w-full flex flex-col'>
      <div className=''>
        {!editUserId && (
          <Button className='h-8 float-right' onClick={() => createNewUser()}>
            Add User
          </Button>
        )}
        {editUserId && (
          <Button className='h-8 float-right' variant='outline' onClick={() => clearEditUser()}>
            Cancel Edit
          </Button>
        )}
      </div>

      <div className='overflow-y-auto flex-1 flex flex-col gap-3 py-3'>
        {isNewUser && <EditUserRow />}
        {users.map((user) =>
          editUserId === user.userId ? (
            <EditUserRow key={user.userId} />
          ) : (
            <UserRow key={user.userId} user={user} />
          )
        )}
      </div>
    </div>
  )
}

export default UsersPage
