import { ListFieldStacked as ListField } from '@/components/ui/List'
import { useFetchStudy } from '@/queries/studies'
import moment from 'moment'

export const ExtraDetails = ({ worklistId }: { worklistId: number }) => {
  const study = useFetchStudy(worklistId)

  if (study.isLoading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <ListField label='Other'>
        <ul className='text-sm text-neutral-400'>
          <li>Status: {`${study.data?.status}`}</li>
          <li>Priority: {`${study.data?.priority}`}</li>
          <li>Series Count: {`${study.data?.seriesCount}`}</li>
          <li>Images Count: {`${study.data?.imagesCount}`}</li>
          <li>Documents Count: {`${study.data?.documentsCount}`}</li>
          <li>Messages Count: {`${study.data?.messagesCount}`}</li>
        </ul>
      </ListField>
      <ListField label='Dates'>
        <div className='text-sm text-neutral-400'>
          {study.data?.receivedDatetime && (
            <div>Received: {moment(study.data?.receivedDatetime).format('M-D-YYYY h:MM A')}</div>
          )}
          {study.data?.studyDatetime && (
            <div>Study: {moment(study.data?.studyDatetime).format('M-D-YYYY h:MM A')}</div>
          )}
          {study.data?.requestedDatetime && (
            <div>Requested: {moment(study.data?.requestedDatetime).format('M-D-YYYY h:MM A')}</div>
          )}
          {study.data?.completedDatetime && (
            <div>Completed: {moment(study.data?.completedDatetime).format('M-D-YYYY h:MM A')}</div>
          )}
        </div>
      </ListField>
    </>
  )
}
