import { Button } from '@/components/ui/Button'
import { formatRole } from '@/lib/formatters'
import { useFetchFacilities } from '@/queries/facility'
import { useFetchPhysicianGroups } from '@/queries/physicianGroup'
import { useEditUserState } from '@/state/editUserState'
import { Loader2 } from 'lucide-react'
import React from 'react'
import { ApiUser } from 'shared'

export const UserRow: React.FC<any> = ({ user }: { user: ApiUser }) => {
  const setEditUser = useEditUserState((state) => state.setEditUser)
  const physicianGroupQuery = useFetchPhysicianGroups()
  const facilitiesQuery = useFetchFacilities()

  if (physicianGroupQuery.isLoading || facilitiesQuery.isLoading) {
    return <Loader2 className='w-4 h-4 animate-spin' />
  }

  const facilityName = () =>
    facilitiesQuery.data?.find((f) => `${f.facilityId}` === `${user.facility}`)?.name
  const physicianGroupName = () =>
    physicianGroupQuery.data?.find((g) => `${g.physicianGroupId}` === `${user.physicianGroup}`)
      ?.groupName

  return (
    <div className='px-4 py-2 flex flex-row w-full rounded-lg border border-neutral-300 shadow items-center'>
      <div className='flex-1'>
        <div className='text-lg font-semibold'>{user.name}</div>
        <div className='text-sm tracking-tight mt-[-3px]'>{user.email}</div>
      </div>
      <div className='flex-1'>{formatRole(user.role)}</div>
      <div className='flex-1'>
        {user.role === 'facility' && facilityName()}
        {user.role === 'physician-group' && physicianGroupName()}
      </div>

      <div className='shrink'>
        <Button variant='outline' onClick={() => setEditUser(user)}>
          Edit
        </Button>
      </div>
    </div>
  )
}
