import { UsersTable } from '@/components/UsersPage/UsersTable'
import { Button } from '@/components/ui/Button'
import { Input } from '@/components/ui/Input'
import { ListContainer, ListField, ListFieldStacked } from '@/components/ui/List'
import { TabBrowser } from '@/components/ui/TabView'
import { useCreateFacilityAlias, useFetchFacility, useUpdateFacility } from '@/queries/facility'
import { useFacilityUsers, useInviteFacilityUser } from '@/queries/user'
import _ from 'lodash'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'
import { ApiFacility } from 'shared'
import { SidePanel } from '../SidePanel'
import { EditFacilityForm } from './EditFacilityForm'
import { FacilityAliasesTable } from './FacilityAliasesTable'

export const FacilityDetails = ({ facilityId }: { facilityId: number }) => {
  const { isLoading, data: facility } = useFetchFacility(facilityId)
  const { data: users } = useFacilityUsers(facilityId)
  const { mutateAsync: updateFacility } = useUpdateFacility(facilityId)
  const { mutateAsync: inviteFacilityUser } = useInviteFacilityUser(facilityId)
  const { mutateAsync: createFacilityAlias } = useCreateFacilityAlias(facilityId)
  const [isEditing, setIsEditing] = useState(false)
  const [inviteEmail, setInviteEmail] = useState('')
  const [newAlias, setNewAlias] = useState('')
  const [currentTab, setCurrentTab] = useState<'aliases' | 'users'>('aliases')
  const hasAddress = facility?.address && facility?.city && facility?.state && facility?.zip

  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-full'>
        <Loader2 className='animate-spin' />
      </div>
    )
  }

  if (!facility) {
    return <div>No facility found</div>
  }

  const handleSave = async (facilityData: Partial<ApiFacility>) => {
    await updateFacility(facilityData)
    setIsEditing(false)
  }

  const sendInvite = async () => {
    await inviteFacilityUser(inviteEmail)
    setInviteEmail('')
  }

  const postNewAlias = async () => {
    await createFacilityAlias(newAlias)
    setNewAlias('')
  }

  const dispAddr = facility?.address ?? 'Unknown'

  return (
    <SidePanel
      label={facility!.name!}
      description={dispAddr}
      canEdit={!isEditing}
      onEdit={() => setIsEditing(true)}>
      {isEditing ? (
        <EditFacilityForm
          facility={facility}
          onSave={handleSave}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <>
          <ListContainer>
            <ListField label='Email'>{facility!.email}</ListField>
            <ListField label='Phone'>{facility!.phone}</ListField>
            {hasAddress && (
              <ListFieldStacked label='Address'>
                <div>
                  {facility!.address}
                  {facility!.address2 ? `, ${facility!.address2}` : ''}
                </div>
                <div>
                  {facility!.city}, {facility!.state} {facility!.zip}
                </div>
              </ListFieldStacked>
            )}
          </ListContainer>

          <TabBrowser
            className='my-4'
            tabs={['aliases', 'users']}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />

          {currentTab === 'aliases' ? (
            _.isArray(facility?.aliases) && facility.aliases.length > 0 ? (
              <>
                <div className='mb-4 flex gap-4'>
                  <Input
                    placeholder='New Alias'
                    value={newAlias}
                    onChange={(e) => setNewAlias(e.target.value)}
                  />
                  <Button variant='outline' onClick={postNewAlias}>
                    Add Alias
                  </Button>
                </div>
                <FacilityAliasesTable facilityId={facilityId} aliases={facility!.aliases ?? []} />
              </>
            ) : (
              <div className='italic text-center'>No aliases to display</div>
            )
          ) : (
            currentTab === 'users' &&
            (_.isArray(users) && users.length > 0 ? (
              <>
                <div className='mb-4 flex gap-4'>
                  <Input
                    placeholder='Email'
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                  />
                  <Button variant='outline' onClick={sendInvite}>
                    Invite User
                  </Button>
                </div>
                <UsersTable users={users!} />
              </>
            ) : (
              <div className='italic text-center'>No users to display</div>
            ))
          )}
        </>
      )}
    </SidePanel>
  )
}
