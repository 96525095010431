import { useCreatePhysicianGroup } from '@/queries/physicianGroup'
import { ApiPhysicianGroup } from 'shared'
import { toast } from 'sonner'
import { SidePanel } from '../SidePanel'
import { EditPhysicianGroup } from './EditPhysicianGroup'

export const NewPhysicianGroup = ({
  onSave,
  onCancel,
}: {
  onSave: (physician: ApiPhysicianGroup) => void
  onCancel: () => void
}) => {
  const { mutateAsync: createPhysicianGroup } = useCreatePhysicianGroup()

  const handleSave = async (group: Partial<ApiPhysicianGroup>) => {
    if (!group.fax) {
      toast.error('Fax is required')
      return
    }

    const data = await createPhysicianGroup(group)
    onSave(data)
  }

  return (
    <SidePanel label='New Physician Group'>
      <EditPhysicianGroup className='mt-4' onSave={handleSave} onCancel={onCancel} />
    </SidePanel>
  )
}
