import { Button } from '@/components/ui/Button'
import { AddressInput, Input, PhoneNumberInput } from '@/components/ui/Input'
import { ListContainer, ListFieldStacked as ListField } from '@/components/ui/List'
import { isValidPhoneNumber } from 'libphonenumber-js'
import _ from 'lodash'
import { useState } from 'react'
import { ApiFacility } from 'shared'
import { toast } from 'sonner'
import { isEmail } from 'validator'
import voca from 'voca'

export const EditFacilityForm = ({
  facility,
  className,
  onSave,
  onCancel,
}: {
  facility?: Partial<ApiFacility>
  className?: string
  onSave: (updatedFacility: Partial<ApiFacility>) => void
  onCancel: () => void
}) => {
  const [name, setName] = useState(facility?.name)
  const [email, setEmail] = useState(facility?.email || '')
  const [phone, setPhone] = useState(facility?.phone || '')
  const [address, setAddress] = useState(facility?.address || '')
  const [address2, setAddress2] = useState(facility?.address2 || '')
  const [city, setCity] = useState(facility?.city || '')
  const [state, setState] = useState(facility?.state || '')
  const [zip, setZip] = useState(facility?.zip || '')

  const handleSave = async () => {
    if (!!phone && !isValidPhoneNumber(phone, 'US')) {
      toast.error('Invalid phone number')
      return
    }

    if (!!email && !isEmail(email)) {
      toast.error('Invalid email address')
      return
    }

    const params = _.pickBy({
      name,
      email,
      phone,
      address,
      address2,
      city,
      state,
      zip,
    })
    onSave(params)
  }

  // TODO: When we have a facility aliases, table, we should allow modifying the facility name
  return (
    <ListContainer className={className}>
      <ListField label='Name'>
        <Input value={voca.titleCase(name ?? '')} onChange={(e) => setName(e.target.value)} />
      </ListField>

      <ListField label='Email'>
        <Input value={email} onChange={(e) => setEmail(e.target.value)} />
      </ListField>

      <ListField label='Phone'>
        <PhoneNumberInput value={phone} onChange={(phone: string) => setPhone(phone)} />
      </ListField>

      <ListField label='Address'>
        <AddressInput
          value={`${address}, ${address2}, ${city}, ${state}, ${zip}`}
          onChange={(address: string) => {
            const [addr1, addr2, city, state, zip] = address.split(', ')
            setAddress(addr1)
            setAddress2(addr2)
            setCity(city)
            setState(state)
            setZip(zip)
          }}
        />
      </ListField>

      <div className='flex flex-row gap-4 pt-3 mx-2'>
        <Button variant='outline' className='flex-1' onClick={handleSave}>
          Save
        </Button>
        <Button variant='outline' className='flex-1' onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </ListContainer>
  )
}
