import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/Table'
import { cn } from '@/lib/utils'
import { useFetchStudyDicoms } from '@/queries/studies'
import { useState } from 'react'

export const DicomStudyTable = ({ worklistId }: { worklistId: number }) => {
  const [selectedSeries, setSelectedSeries] = useState<string | null>(null)
  const { isLoading, data: dicoms } = useFetchStudyDicoms(worklistId)

  return (
    <Table className='w-full'>
      <TableHeader>
        <TableRow>
          <TableHead>M.</TableHead>
          <TableHead>Description</TableHead>
          <TableHead>Images</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isLoading ? (
          <TableRow>
            <TableCell colSpan={3}>Loading...</TableCell>
          </TableRow>
        ) : !!dicoms && dicoms.length > 0 ? (
          dicoms.map((series) => (
            <TableRow
              key={series.seriesUid}
              className={cn({ 'bg-neutral-100': selectedSeries === series.seriesUid })}
              onClick={() => setSelectedSeries(series.seriesUid)}>
              <TableCell>{series.modality}</TableCell>
              <TableCell className='max-w-[150px] text-ellipsis whitespace-nowrap overflow-hidden'>
                {series.seriesDescription ?? <div className='italic'>No Description</div>}
              </TableCell>
              <TableCell>{series.sops?.length ?? 0}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={3} className='text-center italic'>
              No DICOMs
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
