import { ApiUser, UserRole } from 'shared'
import { create } from 'zustand'

interface EditUserState {
  editUserId: string | null
  setEditUser(user: ApiUser): void
  createNewUser(): void
  clearEditUser(): void
  email: string | null
  setEmail: (email: string) => void
  name: string | null
  setName(name: string): void
  role: UserRole | null
  setRole(role: UserRole): void
  facility: string | null
  setFacility(facility: string): void
  physicianGroupId: string | null
  setPhysicianGroupId(physicianGroupId: string): void
  isDeleting: boolean
  setIsDeleting(isDeleting: boolean): void
  isNewUser: boolean
}

export const useEditUserState = create<EditUserState>((set) => ({
  editUserId: null,
  setEditUser: (user) =>
    set({
      editUserId: user.userId,
      email: user.email,
      name: user.name,
      role: user.role,
      facility: user.facility?.toString() ?? null,
      physicianGroupId: user.physicianGroup?.toString() ?? null,
      isNewUser: false,
      isDeleting: false,
    }),
  createNewUser: () =>
    set({
      editUserId: 'new',
      email: null,
      name: null,
      role: null,
      facility: null,
      physicianGroupId: null,
      isNewUser: true,
      isDeleting: false,
    }),
  clearEditUser: () =>
    set({
      editUserId: null,
      email: null,
      name: null,
      role: null,
      facility: null,
      physicianGroupId: null,
      isNewUser: false,
      isDeleting: false,
    }),
  email: null,
  setEmail: (email) => set({ email }),
  name: null,
  setName: (name) => set({ name }),
  role: null,
  setRole: (role) => set({ role }),
  facility: null,
  setFacility: (facility) => set({ facility }),
  physicianGroupId: null,
  setPhysicianGroupId: (physicianGroupId) => set({ physicianGroupId }),
  isDeleting: false,
  setIsDeleting: (isDeleting) => set({ isDeleting }),
  isNewUser: false,
}))
