import { fetchFromApi } from '@/lib/fetchFromApi'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiUser } from 'shared'
import { toast } from 'sonner'

export const useFetchUsers = () => {
  return useQuery({
    queryKey: ['users'],
    queryFn: async (): Promise<ApiUser[]> => {
      const res = await fetchFromApi('users')
      if (!res.ok) {
        throw new Error('Failed to fetch users')
      }
      return await res.json()
    },
  })
}

export const useInviteToken = (token: string) => {
  return useMutation({
    mutationFn: async ({
      firstName,
      lastName,
      password,
    }: {
      firstName: string
      lastName: string
      password: string
    }) => {
      const res = await fetchFromApi(`invite/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName, lastName, password }),
      })

      const data = await res.json()
      if (!res.ok) {
        if (res.status === 409) {
          toast.error('A user with this email already exists')
        } else {
          toast.error('Failed to create account')
        }
      }

      return data
    },
  })
}

export const useInviteFacilityUser = (facilityId: number) => {
  return useMutation({
    mutationFn: async (email: string) => {
      const res = await fetchFromApi(`invite/facility/${facilityId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
      if (!res.ok) {
        toast.error('Failed to invite user')
        throw new Error('Failed to invite user')
      }
      toast.success('Sent invite to ' + email)
      return await res.json()
    },
  })
}

export const useFacilityUsers = (facilityId: number) => {
  return useQuery({
    queryKey: ['users', 'facility', facilityId],
    queryFn: async (): Promise<ApiUser[]> => {
      if (isNaN(facilityId)) throw new Error('Facility ID is not a number')
      const res = await fetchFromApi(`users/facility/${facilityId}`)
      if (!res.ok) throw new Error('Failed to fetch users')
      return await res.json()
    },
  })
}

export const useInvitePhysicianGroupUser = (groupId: number) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (email: string) => {
      const res = await fetchFromApi(`invite/physician-group/${groupId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
      if (!res.ok) {
        toast.error('Failed to invite user')
        throw new Error('Failed to invite user')
      }
      toast.success('Sent invite to ' + email)
      return await res.json()
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users', 'physician-group', groupId] })
    },
  })
}

export const usePhysicianGroupUsers = (groupId: number) => {
  return useQuery({
    queryKey: ['users', 'physician-group', groupId],
    queryFn: async (): Promise<ApiUser[]> => {
      if (isNaN(groupId)) throw new Error('Physician Group ID is not a number')
      const res = await fetchFromApi(`users/physician-group/${groupId}`)
      if (!res.ok) throw new Error('Failed to fetch users')
      return await res.json()
    },
  })
}

export const useCheckInviteToken = (token: string) => {
  return useQuery({
    queryKey: ['invite', token],
    queryFn: async (): Promise<ApiUser> => {
      const res = await fetchFromApi(`invite/${token}`)
      if (!res.ok) {
        throw new Error('Failed to check invite token')
      }
      return await res.json()
    },
    retry: false,
  })
}

export const useCreateUser = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ user, password }: { user: ApiUser; password: string }) => {
      const res = await fetchFromApi('users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user, password }),
      })
      if (!res.ok) {
        throw new Error('Failed to create user')
      }
      return await res.json()
    },
    onMutate: (newUser) => {
      queryClient.cancelQueries({ queryKey: ['users'] })
    },
    onSettled: () => {
      queryClient.refetchQueries({ queryKey: ['users'] })
    },
  })
}

export const useDeleteUser = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (userId: string) => {
      await fetchFromApi(`users/${userId}`, {
        method: 'DELETE',
      })
      return userId
    },
    onMutate: (userId: string) => {
      queryClient.invalidateQueries({ queryKey: ['user', userId] })
    },
    onSettled: () => {
      queryClient.refetchQueries({ queryKey: ['users'] })
    },
  })
}

export const useUpdateUser = (userId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (user: Partial<ApiUser>) => {
      const response = await fetchFromApi(`users/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      })
      if (!response.ok) {
        throw new Error('Failed to update user')
      }
      return await response.json()
    },
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: ['users'] })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user', userId] })
      queryClient.refetchQueries({ queryKey: ['users'] })
    },
  })
}
