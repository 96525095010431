import { IconButton } from '@/components/ui/Button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/Table'
import { Tooltip } from '@/components/ui/Tooltip'
import { useDeleteUser } from '@/queries/user'
import { Trash } from 'lucide-react'
import { ApiUser } from 'shared'

interface UsersTableProps {
  users: ApiUser[]
}

export const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  const { mutateAsync: deleteUser } = useDeleteUser()

  return (
    <div className='w-full'>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Email</TableHead>
            <TableHead className='text-right pr-3'>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users?.length > 0 ? (
            users.map((user) => (
              <TableRow key={user.userId}>
                <TableCell className='font-medium'>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell className='flex justify-end'>
                  {/*
                <Tooltip content='Reset Password'>
                  <IconButton className='h-4 text-neutral-500 hover:text-indigo-500'
                              icon={<KeyRound className='w-4 h-4' />}
                  />
                </Tooltip>
                */}

                  <Tooltip content='Delete User'>
                    <IconButton
                      className='h-4 text-neutral-500 hover:text-red-500'
                      icon={<Trash className='w-4 h-4' />}
                      onClick={() => deleteUser(user.userId!)}
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} className='text-lg text-center italic'>
                No users to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
