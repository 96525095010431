import { fetchFromApi } from '@/lib/fetchFromApi'
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import {
  ApiDicomSeries,
  ApiFile,
  ApiMessage,
  ApiReport,
  ApiStudiesRequest,
  ApiStudy,
  toQueryString,
} from 'shared'
import { toast } from 'sonner'

export const fetchStudiesPage = (getSearchText: () => string) => {
  return async (props: { pageParam?: string }): Promise<ApiStudy[]> => {
    const pageParamParts = props.pageParam?.split('=') ?? []
    const params: ApiStudiesRequest = {
      searchText: getSearchText(),
      previousPageLastDate:
        pageParamParts[0] === 'previousPageLastDate' ? pageParamParts[1] : undefined,
      nextPageFirstDate: pageParamParts[0] === 'nextPageFirstDate' ? pageParamParts[1] : undefined,
    }

    const res = await fetchFromApi(`studies?${toQueryString(params)}`)
    if (!res.ok) {
      toast('Failed to fetch studies', {
        id: 'studies-fetch-error',
      })
      throw new Error('Failed to fetch studies')
    }

    return await res.json()
  }
}

export const useFetchStudies = (getSearchText?: () => string) =>
  useInfiniteQuery({
    queryKey: ['studies'],
    queryFn: fetchStudiesPage(getSearchText ?? (() => '')),
    initialPageParam: undefined,
    getNextPageParam: (prior: ApiStudy[]) => {
      if (!prior || prior.length === 0) return null
      const lastStudy = _.last(prior ?? [])
      return `previousPageLastDate=${lastStudy?.studyDatetime}`
    },
    getPreviousPageParam: (prior: ApiStudy[]) => {
      if (!prior || prior.length === 0) return undefined
      const firstStudy = _.first(prior ?? [])
      return `nextPageFirstDate=${firstStudy?.studyDatetime}`
    },
    //refetchInterval: 30000,
  })

export const useFetchMoreStudiesIfPageUnfilled = (
  getQuery: () => UseInfiniteQueryResult<InfiniteData<ApiStudy[], unknown>, Error>,
  getScrollRef: () => React.RefObject<HTMLDivElement>
) => {
  const query = getQuery()
  const scrollRef = getScrollRef()

  useEffect(() => {
    const handleFetchMore = () => {
      if (!query.hasNextPage) return
      const container = scrollRef.current
      if (container && container.scrollHeight <= container.clientHeight) {
        query.fetchNextPage()
      }
    }

    _.throttle(handleFetchMore, 500, { leading: false })()
  }, [query, scrollRef])
}

export function useFetchStudy(studyId: number) {
  if (isNaN(studyId)) {
    throw new Error('Invalid studyId')
  }

  return useQuery({
    queryKey: ['study', studyId],
    queryFn: async (): Promise<ApiStudy> => {
      const res = await fetchFromApi(`studies/${studyId}`)
      if (!res.ok) {
        toast(`Failed to fetch study: ${studyId}`, {
          id: 'study-fetch-error',
        })
        throw new Error('Failed to fetch study')
      }

      return await res.json()
    },
  })
}

export const useUpdateStudy = (studyId: number) => {
  if (isNaN(studyId)) {
    throw new Error('Invalid studyId')
  }

  const queryClient = useQueryClient()

  const mergeNewData = (data: Partial<ApiStudy>) => {
    // Merge the new data with the existing study
    const study = queryClient.getQueryData<ApiStudy>(['study', studyId])
    queryClient.setQueryData(['study', studyId], { ...study, ...data })
    // Merge the new data with the existing studies/
    queryClient.setQueryData(['studies'], (studies: InfiniteData<ApiStudy[], unknown>) => ({
      ...studies,
      pages: studies?.pages.map((p: ApiStudy[]) =>
        p.map((s?: ApiStudy) => {
          return s?.worklistId === studyId ? { ...s, ...data } : s
        })
      ),
    }))
  }

  return useMutation({
    mutationFn: async (data: Partial<ApiStudy>) => {
      const res = await fetchFromApi(`studies/${studyId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      if (!res.ok) {
        toast(`Failed to update study: ${studyId}`, {
          id: 'study-update-error',
        })
        throw new Error('Failed to update study')
      }
      return await res.json()
    },
    onMutate: (data: Partial<ApiStudy>) => {
      queryClient.cancelQueries({ queryKey: ['study', studyId] })
      mergeNewData(data)
    },
    onSuccess: (data: ApiStudy) => {
      mergeNewData(data)
      queryClient.invalidateQueries({ queryKey: ['study', studyId] })
    },
  })
}

export function useFetchStudyDicoms(studyId: number) {
  if (isNaN(studyId)) {
    throw new Error('Invalid studyId')
  }

  return useQuery({
    queryKey: ['study', studyId, 'dicoms'],
    queryFn: async (): Promise<ApiDicomSeries[]> => {
      const res = await fetchFromApi(`studies/${studyId}/dicoms`)
      if (!res.ok) {
        toast(`Failed to fetch DICOMs for study: ${studyId}`, {
          id: 'dicoms-fetch-error',
        })
        throw new Error('Failed to fetch DICOMs')
      }

      return await res.json()
    },
  })
}

export function useFetchStudyAttachments(studyId: number) {
  if (isNaN(studyId)) {
    throw new Error('Invalid studyId')
  }

  return useQuery({
    queryKey: ['study', studyId, 'attachments'],
    queryFn: async (): Promise<ApiFile[]> => {
      const res = await fetchFromApi(`studies/${studyId}/files`)
      if (!res.ok) {
        toast(`Failed to fetch attachments for study: ${studyId}`, {
          id: 'attachments-fetch-error',
        })
        throw new Error('Failed to fetch attachments')
      }
      return await res.json()
    },
  })
}

export function useGetNewAttachmentURL(studyId: number) {
  if (isNaN(studyId)) {
    throw new Error('Invalid studyId')
  }

  return useMutation({
    mutationFn: async (filename: string) => {
      const res = await fetchFromApi(`studies/${studyId}/files/new?filename=${filename}`)
      return await res.text()
    },
  })
}

export function usePostNewAttachment(studyId: number) {
  if (isNaN(studyId)) {
    throw new Error('Invalid studyId')
  }

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (fileurl: string) => {
      const res = await fetchFromApi(`studies/${studyId}/files/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: fileurl,
      })
      return await res.json()
    },
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: ['study', studyId, 'attachments'] })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['study', studyId, 'attachments'] })
      queryClient.refetchQueries({ queryKey: ['study', studyId] })
      queryClient.refetchQueries({ queryKey: ['studies'] })
    },
  })
}

export function useDownloadAttachment(studyId: number) {
  if (isNaN(studyId)) {
    throw new Error('Invalid studyId')
  }

  return useMutation({
    mutationFn: async (fileId: number) => {
      if (isNaN(fileId)) throw new Error('Invalid fileId')
      const res = await fetchFromApi(`studies/${studyId}/files/${fileId}`)
      return await res.text()
    },
  })
}

export function useDeleteStudyAttachment(studyId: number) {
  if (isNaN(studyId)) {
    throw new Error('Invalid studyId')
  }

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (fileId: number) => {
      if (isNaN(fileId)) throw new Error('Invalid fileId')
      await fetchFromApi(`studies/${studyId}/files/${fileId}`, {
        method: 'DELETE',
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['study', studyId, 'attachments'] })
      queryClient.refetchQueries({ queryKey: ['studies'] })
    },
  })
}

export function useFetchStudyMemos(studyId: number) {
  if (isNaN(studyId)) {
    throw new Error('Invalid studyId')
  }

  return useQuery({
    queryKey: ['study', studyId, 'memos'],
    queryFn: async (): Promise<ApiMessage[]> => {
      const res = await fetchFromApi(`studies/${studyId}/memo`)
      if (!res.ok) {
        toast(`Failed to fetch memos for study: ${studyId}`, {
          id: 'memos-fetch-error',
        })
        throw new Error('Failed to fetch memos')
      }
      return await res.json()
    },
  })
}

export const useAddStudyMemo = (worklistId: number) => {
  if (isNaN(worklistId)) {
    throw new Error('Invalid worklistId')
  }

  const queryClient = useQueryClient()

  return useMutation<ApiMessage, unknown, { message: string }>({
    mutationFn: async ({ message }) => {
      const res = await fetchFromApi(`studies/${worklistId}/memo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: message,
      })
      if (!res.ok) {
        throw new Error('Failed to add message')
      }
      return await res.json()
    },
    onMutate: (memo: Partial<ApiMessage>) => {
      queryClient.cancelQueries({ queryKey: ['study', worklistId, 'memos'] })
      const oldMemos = queryClient.getQueryData<ApiMessage[]>(['study', worklistId, 'memos'])
      queryClient.setQueryData(['study', worklistId, 'memos'], [...(oldMemos ?? []), memo])
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['study', worklistId, 'memos'] })
    },
  })
}

export function useAdjacentStudies(currentWorklistId: number) {
  const query = useFetchStudies(() => '') // Empty search text

  const { previousStudy, nextStudy, previousStudySamePatient, nextStudySamePatient } =
    useMemo(() => {
      if (!query.data) {
        return {
          previousStudy: null,
          nextStudy: null,
          previousStudySamePatient: null,
          nextStudySamePatient: null,
        }
      }

      const allStudies = query.data.pages.flatMap((page) => page)
      const activeStudies = allStudies.filter(
        (study) => study.status === 'Active' || study.status === 'PENDING'
      )
      const currentStudy = allStudies.find((study) => study.worklistId === currentWorklistId)

      if (!currentStudy) {
        return {
          previousStudy: null,
          nextStudy: null,
          previousStudySamePatient: null,
          nextStudySamePatient: null,
        }
      }

      const currentIndex = activeStudies.findIndex((study) => study.worklistId === currentWorklistId)

      // Find adjacent studies (any patient)
      const previousStudy = currentIndex > 0 ? activeStudies[currentIndex - 1] : null
      const nextStudy =
        currentIndex < activeStudies.length - 1 ? activeStudies[currentIndex + 1] : null

      // Find adjacent studies (same patient)
      const samePatientStudies = activeStudies.filter(
        (study) => study.patientId === currentStudy.patientId
      )
      const currentSamePatientIndex = samePatientStudies.findIndex(
        (study) => study.worklistId === currentWorklistId
      )

      const previousStudySamePatient =
        currentSamePatientIndex > 0 ? samePatientStudies[currentSamePatientIndex - 1] : null
      const nextStudySamePatient =
        currentSamePatientIndex < samePatientStudies.length - 1
          ? samePatientStudies[currentSamePatientIndex + 1]
          : null

      return { previousStudy, nextStudy, previousStudySamePatient, nextStudySamePatient }
    }, [query.data, currentWorklistId])

  return {
    previousStudy,
    nextStudy,
    previousStudySamePatient,
    nextStudySamePatient,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
  }
}

export const useUpdateReport = (worklistId: number) => {
  if (isNaN(worklistId)) {
    throw new Error('Invalid worklistId')
  }

  const queryClient = useQueryClient()

  return useMutation<
    ApiReport,
    Error,
    Partial<ApiReport>,
    { previousReport: ApiReport | undefined }
  >({
    mutationFn: async (data: Partial<ApiReport>) => {
      const res = await fetchFromApi(`studies/${worklistId}/report`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      if (!res.ok) {
        const errorMessage = await res.text()
        toast(`Failed to update report: ${errorMessage}`, {
          id: 'report-update-error',
        })
        throw new Error(`Failed to update report: ${errorMessage}`)
      }
      return await res.json()
    },
    onMutate: async (data: Partial<ApiReport>) => {
      await queryClient.cancelQueries({ queryKey: ['study', worklistId, 'report'] })
      const previousReport = queryClient.getQueryData<ApiReport>(['study', worklistId, 'report'])
      queryClient.setQueryData<ApiReport | undefined>(['study', worklistId, 'report'], (old) =>
        old ? { ...old, ...data } : (data as ApiReport)
      )
      return { previousReport }
    },
    onError: (err, newReport, context) => {
      if (context?.previousReport) {
        queryClient.setQueryData(['study', worklistId, 'report'], context.previousReport)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['study', worklistId, 'report'] })
    },
  })
}

export function useFetchStudyAnnotations(studyId: number) {
  return useQuery({
    queryKey: ['study', studyId, 'annotations'],
    queryFn: async () => {
      const res = await fetchFromApi(`studies/${studyId}/annotations`)
      if (res.status === 404) {
        return null // No annotations found
      }
      if (!res.ok && res.status !== 404) {
        toast(`Failed to fetch annotations for study: ${studyId}`, {
          id: 'annotations-fetch-error',
        })
        throw new Error('Failed to fetch annotations')
      }
      return await res.json()
    },
  })
}

export function useSaveAnnotations(studyId: number) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (annotations: any) => {
      const res = await fetchFromApi(`studies/${studyId}/annotations`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(annotations),
      })
      if (!res.ok) {
        throw new Error('Failed to save annotations')
      }
      return res.json()
    },
    onMutate: async (newAnnotations) => {
      await queryClient.cancelQueries({ queryKey: ['study', studyId, 'annotations'] })
      const previousAnnotations = queryClient.getQueryData(['study', studyId, 'annotations'])
      queryClient.setQueryData(['study', studyId, 'annotations'], newAnnotations)
      return { previousAnnotations }
    },
    onError: (err, newAnnotations, context) => {
      queryClient.setQueryData(['study', studyId, 'annotations'], context?.previousAnnotations)
      toast(`Failed to save annotations for study: ${studyId}`, {
        id: 'annotations-save-error',
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['study', studyId, 'annotations'] })
    },
  })
}
