import { PhysiciansTable } from '@/components/PhysiciansPage/PhysiciansTable'
import { SidePanel } from '@/components/SidePanel'
import { Button } from '@/components/ui/Button'
import { Input } from '@/components/ui/Input'
import { ListField, ListFieldStacked } from '@/components/ui/List'
import { TabBrowser } from '@/components/ui/TabView'
import { UsersTable } from '@/components/UsersPage/UsersTable'
import { useFetchPhysicianGroup, useUpdatePhysicianGroup } from '@/queries/physicianGroup'
import { useInvitePhysicianGroupUser, usePhysicianGroupUsers } from '@/queries/user'
import { useAuthStore } from '@/state/authState'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'
import { EditPhysicianGroup } from './EditPhysicianGroup'

export const PhysGroupDetail = ({ groupId }: { groupId: number }) => {
  const isAdmin = useAuthStore((state) => state.role === 'admin')
  const isFacility = useAuthStore((state) => state.role === 'facility')
  const { isLoading, data: group, error } = useFetchPhysicianGroup(groupId)
  const { mutateAsync: updateGroup } = useUpdatePhysicianGroup(groupId)
  const [isEditing, setIsEditing] = useState(false)
  const [inviteEmail, setInviteEmail] = useState('')
  const { data: users } = usePhysicianGroupUsers(groupId)
  const { mutateAsync: inviteUser } = useInvitePhysicianGroupUser(groupId)
  const [currentTab, setCurrentTab] = useState('physicians')
  const hasAddress = group?.address && group?.city && group?.state && group?.zip

  if (isLoading) {
    return (
      <div className='flex justify-center mt-4 h-full'>
        <Loader2 className='animate-spin mr-4' /> Loading Physician Group...
      </div>
    )
  }

  if (error || !group) {
    return (
      <div className='text-red-600 p-4'>
        <h2 className='text-xl font-bold mb-2'>Error Loading Physician Group</h2>
        <p>{error?.message || 'Physician group not found'}</p>
      </div>
    )
  }

  const sendInvite = async () => {
    await inviteUser(inviteEmail)
    setInviteEmail('')
  }

  return (
    <SidePanel
      label={group?.groupName ?? 'Unknown Physician Group'}
      description={group?.facility ?? 'Unassigned'}
      canEdit={(isAdmin || isFacility) && !isEditing}
      onEdit={() => setIsEditing(true)}>
      {isEditing ? (
        <EditPhysicianGroup
          className='overflow-y-auto'
          group={group}
          onSave={async (g) => {
            await updateGroup(g)
            setIsEditing(false)
          }}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <>
          <ListField label='Email Address'>{group.email ?? 'N/A'}</ListField>
          <ListField label='Phone Number'>{group.phone ?? 'N/A'}</ListField>
          <ListField label='Fax Number'>{group.fax ?? 'N/A'}</ListField>
          {hasAddress && (
            <ListFieldStacked label='Address'>
              <div>{group.address}</div>
              {group.address2 && <div>{group.address2}</div>}
              <div>
                {group.city}, {group.state} {group.zip}
              </div>
            </ListFieldStacked>
          )}

          <TabBrowser
            className='my-4'
            tabs={['physicians', 'users']}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />

          {currentTab === 'physicians' ? (
            <PhysiciansTable physicians={group.physicians ?? []} />
          ) : (
            currentTab === 'users' && (
              <>
                <div className='mb-4 flex gap-4'>
                  <Input
                    placeholder='Email'
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                  />
                  <Button variant='outline' onClick={sendInvite}>
                    Invite User
                  </Button>
                </div>
                <UsersTable users={users!} />
              </>
            )
          )}
        </>
      )}
    </SidePanel>
  )
}
