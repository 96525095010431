import { Button } from '@/components/ui/Button'
import { Input } from '@/components/ui/Input'
import { ListFieldStacked as ListField } from '@/components/ui/List'
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js'
import _ from 'lodash'
import { useState } from 'react'
import { ApiPatient } from 'shared'
import { toast } from 'sonner'

export const EditPatientInfo = ({
  patient,
  onSave,
  onCancel,
}: {
  patient: ApiPatient
  onSave: (updatedPatient: Partial<ApiPatient>) => void
  onCancel: () => void
}) => {
  const [firstName, setFirstName] = useState(patient.firstName)
  const [lastName, setLastName] = useState(patient.lastName)
  const [dob, setBirthDate] = useState(patient.birthDate)
  const [email, setEmail] = useState(patient.email)
  const [phoneNumber, setPhoneNumber] = useState(patient.phone)

  const handleSave = async () => {
    if (
      !firstName ||
      !lastName ||
      !_.every([firstName, lastName], (name) => /^[A-Z].+$/.test(name))
    ) {
      toast.error('Please enter a valid first and last name')
      return
    }
    if (!!phoneNumber && !isValidPhoneNumber(phoneNumber, 'US')) {
      toast.error('Invalid phone number')
      return
    }
    onSave(
      _.pickBy({
        name: `${firstName} ${lastName}`,
        birthDate: dob,
        firstName,
        lastName,
        email,
        phone: phoneNumber,
      })
    )
  }

  return (
    <div className='flex flex-col gap-1'>
      <div className='flex flex-row w-full space-x-4'>
        <ListField label='First Name' className='flex-1'>
          <Input value={firstName!} onChange={(e) => setFirstName(e.target.value)} />
        </ListField>
        <ListField label='Last Name' className='flex-1'>
          <Input value={lastName!} onChange={(e) => setLastName(e.target.value)} />
        </ListField>
      </div>

      <ListField label='Date of Birth'>
        <Input
          className='block'
          value={dob!}
          onChange={(e) => setBirthDate(e.target.value)}
          type='date'
        />
      </ListField>

      <ListField label='Email'>
        <Input value={email!} onChange={(e) => setEmail(e.target.value)} />
      </ListField>

      <ListField label='Phone Number'>
        <Input
          value={phoneNumber!}
          onChange={(e) => setPhoneNumber(new AsYouType('US').input(e.target.value))}
          placeholder='(XXX) XXX-XXXX'
        />
      </ListField>

      <div className='flex flex-row gap-4 mt-4 mx-2'>
        <Button variant='outline' className='flex-1' onClick={handleSave}>
          Save
        </Button>
        <Button variant='outline' className='flex-1' onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  )
}
