import { FacilitySelect } from '@/components/FacilitySelect/FacilitySelect'
import { Button } from '@/components/ui/Button'
import { AddressInput, Input, PhoneNumberInput } from '@/components/ui/Input'
import { ListFieldStacked as ListField } from '@/components/ui/List'
import { cn } from '@/lib/utils'
import { useAuthStore } from '@/state/authState'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { useState } from 'react'
import { ApiPhysicianGroup } from 'shared'
import { toast } from 'sonner'

// NOTE: Group is optional because we use this component to create a new group
export const EditPhysicianGroup = ({
  group,
  className,
  onSave,
  onCancel,
}: {
  group?: ApiPhysicianGroup
  className?: string
  onSave: (updatedGroup: Partial<ApiPhysicianGroup>) => void
  onCancel: () => void
}) => {
  const [addr1, addr2] = group?.address?.split(', ') ?? ['', '']
  const [groupName, setGroupName] = useState(group?.groupName ?? '')
  const [email, setEmail] = useState(group?.email ?? '')
  const [phone, setPhone] = useState(group?.phone ?? '')
  const [fax, setFax] = useState(group?.fax ?? '')
  const [address1, setAddress1] = useState(addr1 ?? '')
  const [address2, setAddress2] = useState(addr2 ?? '')
  const [city, setCity] = useState(group?.city ?? '')
  const [state, setState] = useState(group?.state ?? '')
  const [zip, setZip] = useState(group?.zip ?? '')
  const [facilityId, setFacilityId] = useState(group?.facilityId ?? null)
  const { role } = useAuthStore()
  const isAdmin = role === 'admin'

  const handleSave = () => {
    if (!/^[A-Z].+/.test(groupName)) {
      toast.error('Invalid group name')
      return
    }

    if (!!email && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email)) {
      toast.error('Invalid email')
      return
    }

    if (!!phone && !isValidPhoneNumber(phone, 'US')) {
      toast.error('Invalid phone number')
      return
    }

    onSave({
      groupName,
      ...(isAdmin ? { facilityId } : {}),
      email,
      phone,
      fax,
      address: address1,
      address2,
      city,
      state,
      zip,
    })
  }

  return (
    <div className={cn('flex flex-col gap-1', className)}>
      <ListField label='Group Name'>
        <Input value={groupName} onChange={(e) => setGroupName(e.target.value)} />
      </ListField>
      {role === 'admin' && (
        <ListField label='Facility'>
          <FacilitySelect
            selectedFacilityID={facilityId}
            onChange={(facilityId) => setFacilityId(facilityId)}
          />
        </ListField>
      )}
      <ListField label='Email Address'>
        <Input value={email} onChange={(e) => setEmail(e.target.value)} />
      </ListField>
      <ListField label='Phone Number'>
        <PhoneNumberInput value={phone} onChange={(phone: string) => setPhone(phone)} />
      </ListField>
      <ListField label='Fax Number'>
        <PhoneNumberInput value={fax} onChange={(fax: string) => setFax(fax)} />
      </ListField>
      <ListField label='Address' className='flex flex-col gap-1'>
        <AddressInput
          value={[address1, address2, city, state, zip].join(', ')}
          onChange={(address: string) => {
            const [addr1, addr2, city, state, zip] = address.split(', ')
            setAddress1(addr1)
            setAddress2(addr2)
            setCity(city)
            setState(state)
            setZip(zip)
          }}
        />
      </ListField>

      <div className='mt-4 my-4 flex flex-row justify-end gap-2'>
        <Button className='flex-1' variant='outline' onClick={handleSave}>
          Save
        </Button>
        <Button className='flex-1' variant='outline' onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  )
}
