import { Tooltip } from '@/components/ui/Tooltip'
import { cn } from '@/lib/utils'
import * as React from 'react'

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & { variant?: 'studies' }
>(({ className, variant, ...props }, ref) => (
  <div className='relative w-full'>
    <table
      ref={ref}
      className={cn(
        'w-full caption-bottom text-sm',
        variant === 'studies' && 'border-separate border-spacing-y-4',
        className
      )}
      {...props}
    />
  </div>
))
Table.displayName = 'Table'

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn(
      '[&_tr]:border-b border-b',
      '[&_tr]:hover:bg-transparent [&_tr]:hover:cursor-default [&_tr]:select-none',
      className
    )}
    {...props}
  />
))
TableHeader.displayName = 'TableHeader'

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody ref={ref} className={cn('[&_tr:last-child]:border-0', className)} {...props} />
))
TableBody.displayName = 'TableBody'

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn('border-t bg-muted/50 font-medium [&>tr]:last:border-b-0', className)}
    {...props}
  />
))
TableFooter.displayName = 'TableFooter'

const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => (
    <tr
      ref={ref}
      className={cn('border-b transition-colors hover:bg-neutral-100 cursor-pointer', className)}
      {...props}
    />
  )
)
TableRow.displayName = 'TableRow'

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'px-2 py-2 text-left font-normal text-neutral-500 [&:has([role=checkbox])]:pr-0',
      className
    )}
    {...props}
  />
))
TableHead.displayName = 'TableHead'

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & { tooltip?: string | React.ReactNode }
>(({ className, tooltip, ...props }, ref) =>
  tooltip ? (
    <Tooltip content={tooltip}>
      <TableCell ref={ref} className={className} {...props} />
    </Tooltip>
  ) : (
    <td
      ref={ref}
      className={cn('px-2 py-1 align-middle [&:has([role=checkbox])]:pr-0', className)}
      {...props}
    />
  )
)
TableCell.displayName = 'TableCell'

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption ref={ref} className={cn('mt-4 text-sm text-muted-foreground', className)} {...props} />
))
TableCaption.displayName = 'TableCaption'

const StudyTableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      'cursor-pointer h-20 overflow-hidden rounded-lg bg-neutral-50 hover:bg-neutral-100 bg-opacity-50 transition-all duration-300',
      className
    )}
    {...props}
  />
))
StudyTableRow.displayName = 'StudyTableRow'

const StudyTableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & { tooltip?: string | React.ReactNode }
>(({ className, tooltip, ...props }, ref) =>
  tooltip ? (
    <Tooltip content={tooltip}>
      <StudyTableCell ref={ref} className={className} {...props} />
    </Tooltip>
  ) : (
    <td
      ref={ref}
      className={cn(
        'px-2 py-3 align-middle [&:has([role=checkbox])]:pr-0 font-regular text-neutral-600',
        'first:rounded-l-lg last:rounded-r-lg', // Round left edge of first cell and right edge of last cell
        className
      )}
      {...props}
    />
  )
)
StudyTableCell.displayName = 'StudyTableCell'

export {
  StudyTableCell, StudyTableRow, Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow
}

