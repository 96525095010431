import { fetchFromApi } from '@/lib/fetchFromApi'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'
import { ApiPatient, ApiPatientsRequest, toQueryString } from 'shared'
import { toast } from 'sonner'

function pageToken(patient: ApiPatient) {
  return patient.name ?? patient.mrn ?? ''
}

export const fetchPatients = async (props: {
  searchText?: string
  pageParam?: string
}): Promise<ApiPatient[]> => {
  const pageParamParts = props.pageParam?.split('=') ?? []
  const request: ApiPatientsRequest = {
    searchText: props.searchText ?? '',
    previousPageLastToken:
      pageParamParts[0] === 'previousPageLastToken' ? pageParamParts[1] : undefined,
    nextPageFirstToken: pageParamParts[0] === 'nextPageFirstToken' ? pageParamParts[1] : undefined,
  }

  const res = await fetchFromApi(`patients?${toQueryString(request)}`)

  if (!res.ok) {
    toast('Failed to fetch patients', {
      id: 'patients-fetch-error',
    })
    throw new Error('Failed to fetch patients')
  }

  return await res.json()
}

export const useFetchPatients = () => {
  return useInfiniteQuery({
    queryKey: ['patients'],
    queryFn: ({ pageParam }) => {
      return fetchPatients({ pageParam }).then((patients) =>
        patients.filter((patient) => /^\d+$/.test(patient.mrn!))
      )
    },
    initialPageParam: undefined,
    getNextPageParam: (prior: ApiPatient[]) => {
      if (!prior || prior.length === 0) return null
      const lastPatient = _.last(prior)
      return `previousPageLastToken=${pageToken(lastPatient!)}`
    },
    getPreviousPageParam: (prior: ApiPatient[]) => {
      if (!prior || prior.length === 0) return undefined
      const firstPatient = _.first(prior)
      return `nextPageFirstToken=${pageToken(firstPatient!)}`
    },
  })
}

export const useSearchPatients = ({ searchText }: { searchText: string }) => {
  return useInfiniteQuery({
    queryKey: ['patients', 'search', searchText],
    queryFn: ({ pageParam }) => {
      return fetchPatients({ searchText: searchText, pageParam: pageParam }).then((patients) => {
        return patients.filter((patient) => patient.mrn)
      })
    },
    initialPageParam: undefined,
    getNextPageParam: (prior: ApiPatient[]) => {
      if (!prior || prior.length === 0) return null
      const lastPatient = _.last(prior)
      return lastPatient ? `previousPageLastToken=${pageToken(lastPatient)}` : null
    },
    getPreviousPageParam: (prior: ApiPatient[]) => {
      if (!prior || prior.length === 0) return undefined
      const firstPatient = _.first(prior)
      return `nextPageFirstToken=${pageToken(firstPatient!)}`
    },
  })
}

export const useFetchPatient = (patientId: number) => {
  if (isNaN(Number(patientId))) {
    throw new Error('Invalid patientId')
  }

  return useQuery({
    queryKey: ['patient', patientId],
    queryFn: async (): Promise<ApiPatient> => {
      const res = await fetchFromApi(`patients/${patientId}`)
      if (!res.ok) {
        throw new Error('Failed to fetch patient info')
      }
      return await res.json()
    },
  })
}

export const useUpdatePatient = (patientId: number) => {
  if (isNaN(Number(patientId))) {
    throw new Error('Invalid patientId')
  }

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (patient: Partial<ApiPatient>): Promise<ApiPatient> => {
      const res = await fetchFromApi(`patients/${patientId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(patient),
      })

      return await res.json()
    },
    onMutate: async (updatedPatient) => {
      await queryClient.cancelQueries({ queryKey: ['patient', patientId] })
      queryClient.setQueryData(['patient', patientId], updatedPatient)
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['patient', patientId] }),
        queryClient.refetchQueries({ queryKey: ['patients'] }),
      ])
    },
  })
}
