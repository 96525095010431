import { useAuthStore } from '@/state/authState'
//import { refreshToken } from './auth'
// TODO:

// Fetches from the API with the correct credentials
// this supplies the API endpoint when including credentials in order to
// avoid the possiblity of accidentally sending credentials to a non-API endpoint
export const fetchFromApi = async (endpointStem: string, options: RequestInit = {}) => {
  const url = `${process.env.REACT_APP_API_URL}/api/${endpointStem}`

  const result = await fetchWithCredentials(url, options)
  if (result.status === 401) {
    try {
      //await refreshToken()
      return await fetchWithCredentials(url, options)
    } catch (err) {
      useAuthStore.getState().clearUser()
      throw err
    }
  }
  return result
}

// Fetches from the API with the correct credentials
// Please do not call this directly - call fetchFromApi instead
const fetchWithCredentials = async (url: string, options: RequestInit = {}) => {
  const token = useAuthStore.getState().token
  const headers = new Headers(options.headers)
  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }
  return fetch(url, { ...options, headers })
}
