import { fetchFromApi } from '@/lib/fetchFromApi'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiPhysician, ApiPhysicianGroup } from 'shared'

export const useFetchPhysicianGroups = () => {
  return useQuery({
    queryKey: ['physician-groups'],
    queryFn: async (): Promise<ApiPhysicianGroup[]> => {
      const res = await fetchFromApi('group')
      if (!res.ok) {
        throw new Error('Failed to fetch physician groups')
      }
      return await res.json()
    },
  })
}

export const useFetchPhysicianGroup = (groupId: number) => {
  if (isNaN(groupId)) {
    throw new Error('Invalid groupId')
  }

  return useQuery({
    queryKey: ['physician-group', groupId],
    queryFn: async (): Promise<ApiPhysicianGroup> => {
      const res = await fetchFromApi(`group/${groupId}`)
      if (!res.ok) {
        throw new Error('Failed to fetch physician patient groups')
      }
      return await res.json()
    },
  })
}

export const useFetchZombiePhysicians = () =>
  useQuery({
    queryKey: ['physician-group', 'none'],
    queryFn: async (): Promise<ApiPhysician[]> => {
      const res = await fetchFromApi('group/none')
      if (!res.ok) {
        throw new Error('Failed to fetch physicians')
      }
      return await res.json()
    },
  })

export const useCreatePhysicianGroup = () => {
  const queryClient = useQueryClient()

  return useMutation<ApiPhysicianGroup, unknown, Partial<ApiPhysicianGroup>>({
    mutationFn: async (groupData: Partial<ApiPhysicianGroup>) => {
      const res = await fetchFromApi(`group`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(groupData),
      })
      if (!res.ok) {
        throw new Error('Failed to create physician patient group')
      }
      return await res.json()
    },
    onSuccess: (group: ApiPhysicianGroup) => {
      const { physicianGroupId } = group
      queryClient.setQueryData(['physicianGroup', physicianGroupId], group)
    },
  })
}

export const useUpdatePhysicianGroup = (groupId: number) => {
  if (isNaN(groupId)) {
    throw new Error('Invalid groupId')
  }

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (group: Partial<ApiPhysicianGroup>) => {
      const res = await fetchFromApi(`group/${groupId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(group),
      })
      if (!res.ok) {
        throw new Error('Failed to update physician patient group')
      }
      return await res.json()
    },
    onMutate: async (updatedGroup) => {
      await queryClient.cancelQueries({ queryKey: ['physician-group', groupId] })
      queryClient.setQueryData(['physician-group', groupId], updatedGroup)
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['physician-group', groupId] }),
        queryClient.refetchQueries({ queryKey: ['physician-groups'] }),
      ])
    },
  })
}
