import { fetchFromApi } from '@/lib/fetchFromApi'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiPhysician } from 'shared'

export const useFetchPhysicians = () =>
  useQuery({
    queryKey: ['physicians'],
    queryFn: async (): Promise<ApiPhysician[]> => {
      const res = await fetchFromApi('physician')
      if (!res.ok) {
        throw new Error('Failed to fetch physicians')
      }
      return await res.json()
    },
  })

export const useFetchPhysician = (physicianId: number) => {
  if (isNaN(Number(physicianId))) {
    throw new Error('Invalid physicianId')
  }

  return useQuery({
    queryKey: ['physician', physicianId],
    queryFn: async (): Promise<ApiPhysician> => {
      const res = await fetchFromApi(`physician/${physicianId}`)
      if (!res.ok) {
        throw new Error('Failed to fetch physician patients')
      }
      return await res.json()
    },
  })
}

export const useCreatePhysician = () => {
  const queryClient = useQueryClient()

  return useMutation<ApiPhysician, unknown, Partial<ApiPhysician>>({
    mutationFn: async (physicianData: Partial<ApiPhysician>) => {
      const res = await fetchFromApi('physician', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(physicianData),
      })
      if (!res.ok) {
        throw new Error('Failed to create physician')
      }
      return await res.json()
    },
    onSuccess: (physician: ApiPhysician) => {
      const { physicianId } = physician
      queryClient.setQueryData(['physician', physicianId], physician)
    },
  })
}

export const updatePhysician = async (physicianId: number, physician: Partial<ApiPhysician>) => {
  const res = await fetchFromApi(`physician/${physicianId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(physician),
  })
  if (!res.ok) {
    throw new Error('Failed to update physician')
  }
  return await res.json()
}

export const useUpdatePhysician = (physicianId: number) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (physician: Partial<ApiPhysician>) => {
      return updatePhysician(physicianId, physician)
    },
    onMutate: async (updatedPhysician) => {
      await queryClient.cancelQueries({ queryKey: ['physician', physicianId] })
      await queryClient.setQueryData(['physician', physicianId], updatedPhysician)
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['physician', physicianId] }),
        queryClient.refetchQueries({ queryKey: ['physician-groups'] }),
      ])
    },
  })
}
