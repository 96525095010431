import _ from 'lodash'
import { CheckCircle2, LoaderCircle, TriangleAlert, XCircle } from 'lucide-react'

export type StatusColor = 'red' | 'yellow' | 'green' | 'transparent'
export type StudyStatus = 'pending' | 'in-progress' | 'completed' | 'cancelled'

export const StatusBubble = ({
  color,
  className,
}: {
  color: StatusColor | (() => StatusColor)
  className?: string
}) => {
  const statusColor = _.isFunction(color) ? color() : color

  const getColorAlias = (color: string) => {
    switch (color) {
      case 'red':
        return 'bg-red-500'
      case 'yellow':
        return 'bg-yellow-500'
      case 'green':
        return 'bg-green-600'
      case 'transparent':
        return 'bg-transparent'
      default:
        throw new Error(`Invalid status color: ${color}`)
    }
  }

  return <div className={`w-2 h-2 rounded-full ${className} ${getColorAlias(statusColor)}`}></div>
}

export const StatusIcon = ({ status, className }: { status: StudyStatus; className?: string }) => {
  const getStatusIcon = () => {
    switch (status) {
      case 'pending':
        return <TriangleAlert className={className} />
      case 'in-progress':
        return <LoaderCircle className={className} />
      case 'completed':
        return <CheckCircle2 className={className} />
      case 'cancelled':
        return <XCircle className={className} />
      default:
        return null
    }
  }

  return getStatusIcon()
}
