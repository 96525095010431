import { cn } from '@/lib/utils'
import * as T from '@radix-ui/react-tooltip'
import * as React from 'react'

interface TooltipProps {
  content: React.ReactNode
  children: React.ReactNode
  tooltipClassName?: string
  sideOffset?: number
  delayDuration?: number
  isPopoverOpen?: boolean
  isHoveringInPopover?: boolean
}

const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      content,
      children,
      tooltipClassName,
      sideOffset = 5,
      delayDuration = 200,
      isPopoverOpen = false,
      isHoveringInPopover = false,
    },
    ref
  ) => {
    const tooltipInPopover = isPopoverOpen && isHoveringInPopover
    const tooltipOutsidePopover = !isPopoverOpen && !isHoveringInPopover
    const shouldShowTooltip = tooltipInPopover || tooltipOutsidePopover

    return (
      <T.Provider>
        <T.Root delayDuration={delayDuration} disableHoverableContent>
          <T.Trigger asChild>{children}</T.Trigger>
          {shouldShowTooltip && (
            <T.Portal>
              <T.Content
                ref={ref}
                side='bottom'
                sideOffset={sideOffset}
                className={cn(
                  'bg-neutral-800 text-white p-2 rounded text-xs max-w-xs break-words z-50',
                  tooltipClassName
                )}>
                {content}
              </T.Content>
            </T.Portal>
          )}
        </T.Root>
      </T.Provider>
    )
  }
)

Tooltip.displayName = 'Tooltip'

export { Tooltip }
