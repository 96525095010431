import { ListContainer, ListField } from '@/components/ui/List'
import { humanReadableDateAndYear } from '@/lib/dates'
import { useFetchPhysician } from '@/queries/physician'
import { Link } from 'react-router-dom'
import { ApiPatient } from 'shared'
import voca from 'voca'

export const PatientInfo = ({ patient }: { patient: ApiPatient }) => {
  const referringPhysicianId = patient.studies![0]?.referringPhysicianId ?? -1
  const { data: referringPhysician } = useFetchPhysician(referringPhysicianId)
  let { name, firstName, lastName } = patient

  if (!!firstName && !!lastName) {
    name = `${firstName} ${lastName}`
  } else if (!name) {
    name = 'Unknown'
  }

  return (
    <ListContainer>
      <ListField label='Physician'>
        {!!referringPhysician ? (
          <Link
            to={`/physicians/${referringPhysicianId}`}
            className='text-indigo-700 hover:text-indigo-500 font-medium'>
            {voca.titleCase(referringPhysician.name)}
          </Link>
        ) : (
          'N/A'
        )}
      </ListField>

      <ListField label='Date of Birth'>
        {!!patient.birthDate ? humanReadableDateAndYear(patient.birthDate!) : 'N/A'}
      </ListField>

      <ListField label='Email Address'>{patient.email ?? 'N/A'}</ListField>

      <ListField label='Phone Number'>{patient.phone ?? 'N/A'}</ListField>
    </ListContainer>
  )
}
