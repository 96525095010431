import { Button } from '@/components/ui/Button'
import { Card } from '@/components/ui/Card'
import { Textarea } from '@/components/ui/Textarea'
import { humanReadableDate, humanReadableTime } from '@/lib/dates'
import { useAddStudyMemo, useFetchStudyMemos } from '@/queries/studies'
import { useRef } from 'react'
import { ApiMessage } from 'shared'

export const WorklistMessages = ({ worklistId }: { worklistId: number }) => {
  const { isLoading, data: memos } = useFetchStudyMemos(worklistId)
  const addMessage = useAddStudyMemo(worklistId)
  const messageRef = useRef<HTMLTextAreaElement>(null)

  function sendMessage() {
    if (messageRef.current) {
      const message = messageRef.current.value.trim()
      if (message.length > 0) {
        addMessage.mutate({ message })
      }
      messageRef.current.value = ''
    }
  }

  return (
    <div>
      <div className='flex flex-col'>
        <Textarea ref={messageRef} placeholder='Write a message' />
        <Button className='self-end mt-2' onClick={sendMessage}>
          Send
        </Button>
      </div>
      <h1 className='text-lg font-bold'>Memos</h1>
      {isLoading ? (
        <div className='text-sm font-semibold italic'>Loading...</div>
      ) : !memos || memos?.length === 0 ? (
        <div className='text-sm font-semibold italic'>No messages</div>
      ) : (
        memos.map((memo) => <Message key={memo.messageId} memo={memo} />)
      )}
    </div>
  )
}

const Message = ({ memo }: { memo: ApiMessage }) => {
  const { createdDatetime, createdBy, createdByRole, message } = memo
  return (
    <Card className='my-2 py-2 px-4'>
      <div className='flex flex-row justify-between items-center'>
        <div className='flex items-end gap-2'>
          <div className='text-md font-bold'>{createdBy}</div>
          <div className='text-sm text-muted-foreground'>{createdByRole ?? ''}</div>
        </div>
        <div className='text-xs ml-1 mb-0.5 flex items-end'>
          <div className='font-semibold mr-2'>{humanReadableDate(createdDatetime!)}</div>
          {humanReadableTime(createdDatetime!)}
        </div>
      </div>

      <div>{message}</div>
    </Card>
  )
}
