import { cn } from '@/lib/utils'
import { Clock } from 'lucide-react'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'

export const TimerBadge = ({ studyDatetime }: { studyDatetime: string }) => {
  const [timeLeft, setTimeLeft] = useState('')
  const [isOverdue, setIsOverdue] = useState(false)

  useEffect(() => {
    // For testing 
    // const studyDatetime = '2024-12-20T18:00:00Z'

    const updateTimer = () => {
      const deadline = moment(studyDatetime).add(2, 'hours')
      const now = moment()
      if (now.isAfter(deadline)) {
        setTimeLeft('Overdue')
        setIsOverdue(true)
        return
      }
      const duration = moment.duration(deadline.diff(now))
      setTimeLeft(`${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`)
      setIsOverdue(false)
    }

    updateTimer()
    const interval = setInterval(updateTimer, 1000)
    return () => clearInterval(interval)
  }, [studyDatetime])

  return (
    <div className='relative flex items-center justify-center bg-indigo-50 border border-indigo-300 rounded-lg py-1 px-3 w-[120px] mt-2'>
      {!isOverdue && <Clock className='absolute left-2 w-4 h-4 text-indigo-500' />}
      <span
        className={cn('text-indigo-600 text-sm font-medium', isOverdue ? 'text-center' : 'pl-6')}>
        {timeLeft}
      </span>
    </div>
  )
}
