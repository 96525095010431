import { IconButton } from '@/components/ui/Button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/Table'
import { Tooltip } from '@/components/ui/Tooltip'
import { useDeleteFacilityAlias } from '@/queries/facility'
import { Trash } from 'lucide-react'
import React from 'react'
import voca from 'voca'

interface FacilityAliasesTableProps {
  facilityId: number
  aliases: string[]
}

export const FacilityAliasesTable: React.FC<FacilityAliasesTableProps> = ({
  aliases,
  facilityId,
}) => {
  const { mutateAsync: deleteFacilityAlias } = useDeleteFacilityAlias(facilityId)

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>ID</TableHead>
          <TableHead>Alias</TableHead>
          <TableHead className='text-right pr-3'>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {aliases.map((alias, i) => (
          <TableRow key={alias}>
            <TableCell className='font-medium'>{i + 1}</TableCell>
            <TableCell>{voca.titleCase(alias)}</TableCell>
            <TableCell className='flex justify-end'>
              <Tooltip content='Delete User'>
                <IconButton
                  className='h-4 text-neutral-500 hover:text-red-500'
                  icon={<Trash className='w-4 h-4' />}
                  onClick={() => deleteFacilityAlias(alias)}
                />
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
