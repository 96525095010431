import { Button } from '@/components/ui/Button'
import { Input } from '@/components/ui/Input'
import { Select, SelectContent, SelectTrigger, SelectValue } from '@/components/ui/Select'
import { formatRole } from '@/lib/formatters'
import { useFetchFacilities } from '@/queries/facility'
import { useFetchPhysicianGroups } from '@/queries/physicianGroup'
import { useCreateUser, useDeleteUser, useUpdateUser } from '@/queries/user'
import { useEditUserState } from '@/state/editUserState'
import { SelectItem } from '@radix-ui/react-select'
import { TrashIcon } from 'lucide-react'
import { ApiUser } from 'shared'

export const EditUserRow: React.FC = () => {
  const name = useEditUserState((state) => state.name)
  const email = useEditUserState((state) => state.email)
  const setName = useEditUserState((state) => state.setName)
  const setEmail = useEditUserState((state) => state.setEmail)
  const role = useEditUserState((state) => state.role)
  const clearEditUser = useEditUserState((state) => state.clearEditUser)
  const facilityId = useEditUserState((state) => state.facility)
  const physicianGroupId = useEditUserState((state) => state.physicianGroupId)
  const isDeleting = useEditUserState((state) => state.isDeleting)
  const setIsDeleting = useEditUserState((state) => state.setIsDeleting)
  const isNewUser = useEditUserState((state) => state.isNewUser)

  const facilitiesQuery = useFetchFacilities()
  const physicianGroupsQuery = useFetchPhysicianGroups()
  const createUserMutation = useCreateUser()
  const updateUserMutation = useUpdateUser(useEditUserState.getState().editUserId!)
  const deleteUserMutation = useDeleteUser() //TODO: check if this is correct

  const isValid = () => {
    if (!role) return false
    if (!name) return false
    if (role === 'facility') {
      return !!facilitiesQuery.data?.find((f) => `${f.facilityId}` === facilityId)
    }
    if (role === 'physician-group') {
      return !!physicianGroupsQuery.data?.find((g) => `${g.physicianGroupId}` === physicianGroupId)
    }
    if (isNewUser && !email) return false
    return true
  }

  const handleSave = () => {
    const user: ApiUser = {
      userId: isNewUser ? '' : useEditUserState.getState().editUserId!,
      name: name!,
      email: email!,
      role: role!,
      facility: role === 'facility' ? Number(facilityId!) : 0,
      physicianGroup: role === 'physician-group' ? Number(physicianGroupId!) : 0,
    }
    if (isNewUser) {
      let password: string | null = null
      while (!password) {
        password = prompt('Enter a password for the new user:')
      }
      createUserMutation.mutate({ user, password })
    } else {
      updateUserMutation.mutate(user)
    }
    clearEditUser()
  }

  const handleDelete = () => {
    deleteUserMutation.mutate(useEditUserState.getState().editUserId!)
    clearEditUser()
  }

  return (
    <div className='px-1 py-2 w-full rounded-lg border border-neutral-300 shadow-md items-left'>
      <div className='flex flex-row w-full items-center'>
        <div className='px-2 flex-grow'>
          <Input
            className='text-lg font-semibold w-'
            value={name ?? ''}
            onChange={(e) => setName(e.target.value)}
            placeholder='Name'
          />
        </div>
        <div className='px-2 w-50'>
          <SelectRole />
        </div>
        {role === 'facility' && (
          <div className='px-2 w-56'>
            <SelectFacility />
          </div>
        )}
        {role === 'physician-group' && (
          <div className='px-2 w-56'>
            <SelectPhysicianGroup />
          </div>
        )}
      </div>
      <div className='w-full pt-2 flex flex-row justify-between'>
        <div className='px-2 w-52'>
          <Input
            className='w-full h-8'
            value={email ?? ''}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='Email'
          />
        </div>
        <div className='bg-neutral-500 px-2 flex flex-row gap-2'>
          {isDeleting && (
            <>
              <Button className='h-8' variant='outline' onClick={() => setIsDeleting(false)}>
                Cancel Delete
              </Button>
              <Button className='h-8 bg-red-500 text-white font-bold' onClick={handleDelete}>
                Confirm Delete
              </Button>
            </>
          )}
          {!isDeleting && (
            <>
              <Button
                className='h-8 text-neutral-500 hover:text-neutral-950'
                variant='ghost'
                onClick={() => setIsDeleting(true)}>
                <TrashIcon size={15} />
              </Button>
              <Button className='h-8' variant='outline' onClick={() => clearEditUser()}>
                Cancel
              </Button>
              <Button
                className='h-8'
                variant='default'
                disabled={!isValid()}
                onClick={() => handleSave()}>
                Save
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const SelectRole = () => {
  const role = useEditUserState((state) => state.role)
  const setRole = useEditUserState((state) => state.setRole)
  return (
    <Select value={role ?? undefined} onValueChange={setRole}>
      <SelectTrigger>
        <SelectValue placeholder='Select Role...'>{role && formatRole(role)}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value='admin'>Admin</SelectItem>
        <SelectItem value='radiologist'>Radiologist</SelectItem>
        <SelectItem value='facility'>Facility</SelectItem>
        <SelectItem value='physician-group'>Physician Group</SelectItem>
      </SelectContent>
    </Select>
  )
}

const SelectFacility = () => {
  const facilityId = useEditUserState((state) => state.facility)
  const setFacility = useEditUserState((state) => state.setFacility)

  const facilitiesQuery = useFetchFacilities()
  const facility = facilitiesQuery.data?.find((f) => `${f.facilityId}` === facilityId)

  return (
    <Select value={facilityId ?? undefined} onValueChange={setFacility}>
      <SelectTrigger>
        <SelectValue placeholder='Select Facility...'>{facility?.name ?? ''}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        {facilitiesQuery.data?.map((f) => (
          <SelectItem key={f.facilityId} value={`${f.facilityId}`}>
            {f.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

const SelectPhysicianGroup = () => {
  const groupId = useEditUserState((state) => state.physicianGroupId)
  const setGroupId = useEditUserState((state) => state.setPhysicianGroupId)

  const physicianGroupsQuery = useFetchPhysicianGroups()
  const group = physicianGroupsQuery.data?.find((g) => `${g.physicianGroupId}` === groupId)

  return (
    <Select value={groupId ?? undefined} onValueChange={setGroupId}>
      <SelectTrigger>
        <SelectValue placeholder='Select Group...'>{group?.groupName ?? ''}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        {physicianGroupsQuery.data?.map((g) => (
          <SelectItem key={g.physicianGroupId} value={`${g.physicianGroupId}`}>
            {g.groupName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
