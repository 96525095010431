import { Label } from '@/components/ui/Label'
import { ReactNode } from 'react'

export const ListContainer = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return <div className={`space-y-1 overflow-y-auto ${className}`}>{children}</div>
}

export function ListField({
  label,
  children,
  className,
  labelClass,
}: {
  label: string
  children: ReactNode
  className?: string
  labelClass?: string
}) {
  return (
    <div
      className={`flex items-center overflow-hidden whitespace-nowrap text-ellipsis ${className}`}>
      <Label className={`shrink ml-1 mr-2 ${labelClass}`}>{label}</Label>
      <div className='flex-1'>{children}</div>
    </div>
  )
}

export const ListFieldStacked = ({
  label,
  children,
  className,
}: {
  label: string
  children: ReactNode
  className?: string
}) => {
  return (
    <div className={`pb-2 flex flex-col ${className}`}>
      <Label className='ml-1 mb-1.5 font-normal text-sm text-neutral-500 select-none'>{label}</Label>
      <div className='ml-1  mb-1 text-sm text-neutral-600'>{children}</div>
    </div>
  )
}
