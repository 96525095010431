import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/Table'
import { humanReadableDate } from '@/lib/dates'
import { useNavigate } from 'react-router-dom'
import { ApiPhysician } from 'shared'
import voca from 'voca'

export const PhysiciansTable = ({
  label,
  physicians,
  selectedPhysicianID,
  selectedGroupID,
  className,
  showHeader = true,
}: {
  label?: string | React.ReactNode
  physicians: ApiPhysician[]
  selectedPhysicianID?: number
  selectedGroupID?: number
  className?: string
  showHeader?: boolean
}) => {
  const navigate = useNavigate()

  return (
    <div className={`mx-1 overflow-x-auto ${className}`}>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead
              className={`whitespace-nowrap ${
                label && 'text-indigo-700 hover:text-indigo-500 font-medium'
              }`}
              colSpan={showHeader ? 1 : 3}>
              {label ?? 'Name'}
            </TableHead>
            {showHeader && (
              <>
                <TableHead className='text-center'>Referrals</TableHead>
                <TableHead className='whitespace-nowrap'>Last Referral</TableHead>
              </>
            )}
          </TableRow>
        </TableHeader>

        <TableBody>
          {!!physicians && physicians.length > 0 ? (
            physicians.map((physician: ApiPhysician, i: number) => (
              <TableRow
                key={i}
                className={`${
                  selectedPhysicianID === physician.physicianId &&
                  'bg-neutral-100 hover:bg-neutral-200'
                }`}
                onClick={() => {
                  navigate(`/physicians/${physician.physicianId}`)
                }}>
                <TableCell className='w-[60%]'>{voca.titleCase(physician.name)}</TableCell>
                <TableCell className='w-[20%] text-center'>
                  {Number(physician.studiesCount) ?? 0}
                </TableCell>
                <TableCell className='w-[20%]'>
                  {physician.lastStudyReferralDate
                    ? humanReadableDate(physician.lastStudyReferralDate)
                    : 'N/A'}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow className='text-center italic'>
              <TableCell colSpan={3} className='pt-2'>
                No physicians to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
