import { Button } from '@/components/ui/Button';
import { downloadFile } from '@/lib/downloadFile';
import { cn } from '@/lib/utils';

export const DownloadReport = ({ url, className }: { url: string | null; className?: string }) => {
  return (
    <Button
      variant='outline'
      className={cn('w-full', className)}
      onClick={() => (!!url ? downloadFile(url) : null)}>
      Download Report
    </Button>
  )
}
