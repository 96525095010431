import { cn } from '@/lib/utils'
import { AsYouType } from 'libphonenumber-js'
import { ChevronDown, ChevronUp, FilePlusIcon } from 'lucide-react'
import React, { useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border border-neutral-200 bg-background px-6 py-2 text-sm  file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
          '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = 'Input'

export const PhoneNumberInput = ({
  value,
  onChange,
  ...props
}: {
  value: string | null
  onChange: (phoneNumber: string) => void
}) => {
  return (
    <Input
      type='tel'
      value={value ?? ''}
      onChange={(e) => {
        const phoneNumber = new AsYouType('US').input(e.target.value)
        onChange(phoneNumber)
      }}
      placeholder='(XXX) XXX-XXXX'
      {...props}
    />
  )
}

export const AddressInput = ({
  className,
  value,
  onChange,
}: {
  className?: string
  value: string
  onChange: (address: string) => void
}) => {
  const [pAddr1, pAddr2, pCity, pState, pZip] = value.split(', ')
  const [address1, setAddress1] = useState(pAddr1)
  const [address2, setAddress2] = useState(pAddr2)
  const [city, setCity] = useState(pCity)
  const [state, setState] = useState(pState)
  const [zip, setZip] = useState(pZip)

  return (
    <div className={cn('flex flex-col gap-1', className)}>
      <Input
        value={address1}
        placeholder='Address 1'
        onChange={(e) => {
          const address = e.target.value
          setAddress1(address)
          onChange([address, address2, city, state, zip].join(', '))
        }}
      />
      <Input
        value={address2}
        placeholder='Address 2'
        onChange={(e) => {
          const address2 = e.target.value
          setAddress2(address2)
          onChange([address1, address2, city, state, zip].join(', '))
        }}
      />
      <div className='flex flex-row gap-1'>
        <Input
          value={city}
          placeholder='City'
          onChange={(e) => {
            const city = e.target.value
            setCity(city)
            onChange([address1, address2, city, state, zip].join(', '))
          }}
        />
        <Input
          value={state}
          placeholder='State'
          onChange={(e) => {
            const state = e.target.value
            setState(state)
            onChange([address1, address2, city, state, zip].join(', '))
          }}
        />
        <Input
          value={zip}
          placeholder='Zip'
          onChange={(e) => {
            const zip = e.target.value
            setZip(zip)
            onChange([address1, address2, city, state, zip].join(', '))
          }}
        />
      </div>
    </div>
  )
}

export const FileInput = ({
  selectedFile,
  onChange,
}: {
  selectedFile: File | null
  onChange: (file: File) => void
}) => {
  const dropzoneOptions: Partial<DropzoneOptions> = {
    onDrop: (files: File[]) => onChange(files[0]),
    multiple: false,
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    dropzoneOptions as DropzoneOptions
  )

  return (
    <div
      {...getRootProps()}
      className='border-dashed border-2 border-neutral-300 rounded-md p-2 italic hover:cursor-pointer'>
      <input {...(getInputProps() as React.InputHTMLAttributes<HTMLInputElement>)} />
      <div className='flex items-center justify-center gap-3 whitespace-nowrap overflow-hidden'>
        <FilePlusIcon className='w-4 h-4' />
        {selectedFile
          ? selectedFile.name
          : isDragActive
          ? 'Drop files here'
          : 'Select files to upload'}
      </div>
    </div>
  )
}

export const NumberInput = React.forwardRef<
  HTMLInputElement,
  InputProps & { onValueChange?: (value: number) => void }
>(({ className, onChange, onValueChange, value, min, max, ...props }, ref) => {
  return (
    <div className='relative'>
      <Input
        type='number'
        ref={ref}
        value={value}
        onChange={onChange}
        className={cn('pr-8', className)}
        min={min}
        max={max}
        {...props}
      />
      <div className='absolute right-3 top-1 flex flex-col'>
        <button
          type='button'
          className='h-4 w-4 bg-transparent hover:bg-accent flex items-center justify-center rounded-sm'
          onClick={() => {
            const currentValue = Number(value)
            const newValue = currentValue + 1
            if (!max || newValue <= Number(max)) {
              onValueChange?.(newValue)
            }
          }}>
          <ChevronUp className='h-3 w-3' />
        </button>
        <button
          type='button'
          className='h-4 w-4 bg-transparent hover:bg-accent flex items-center justify-center rounded-sm'
          onClick={() => {
            const currentValue = Number(value)
            const newValue = currentValue - 1
            if (!min || newValue >= Number(min)) {
              onValueChange?.(newValue)
            }
          }}>
          <ChevronDown className='h-3 w-3' />
        </button>
      </div>
    </div>
  )
})
NumberInput.displayName = 'NumberInput'
