import { create } from 'zustand'

interface GlobalState {
  mouseIsDown: boolean
  setMouseIsDown: (isDown: boolean) => void
}

export const useGlobalState = create<GlobalState>((set) => ({
  mouseIsDown: false,
  setMouseIsDown: (isDown) => set({ mouseIsDown: isDown }),
}))
