import { PhysicianGroupSelect } from '@/components/PhysGroupSelect/PhysicianGroupSelect'
import { Button } from '@/components/ui/Button'
import { AddressInput, Input, PhoneNumberInput } from '@/components/ui/Input'
import { ListContainer, ListFieldStacked as ListField } from '@/components/ui/List'
import { isValidPhoneNumber } from 'libphonenumber-js'
import _ from 'lodash'
import { useState } from 'react'
import { ApiPhysician } from 'shared'
import { toast } from 'sonner'

export const EditPhysicianInfo = ({
  physician,
  className,
  onSave,
  onCancel,
}: {
  physician?: Partial<ApiPhysician>
  className?: string
  onSave: (updatedPhysician: Partial<ApiPhysician>) => void
  onCancel: () => void
}) => {
  const [firstName, setFirstName] = useState(physician?.firstName ?? '')
  const [lastName, setLastName] = useState(physician?.lastName ?? '')
  const [email, setEmail] = useState(physician?.email ?? '')
  const [phone, setPhone] = useState(physician?.phone ?? '')
  const [fax, setFax] = useState(physician?.fax ?? '')
  const [address, setAddress] = useState(physician?.address ?? '')
  const [address2, setAddress2] = useState(physician?.address2 ?? '')
  const [city, setCity] = useState(physician?.city ?? '')
  const [state, setState] = useState(physician?.state ?? '')
  const [zip, setZip] = useState(physician?.zip ?? '')
  const [groupId, setGroupId] = useState(physician?.physicianGroupId)

  const handleSave = async () => {
    if (!_.every([firstName, lastName], (name) => /^[A-Z].+$/.test(name))) {
      toast.error('Please enter a valid name')
      return
    }

    if (!!phone && !isValidPhoneNumber(phone, 'US')) {
      toast.error('Invalid phone number')
      return
    }

    if (!!fax && !isValidPhoneNumber(fax, 'US')) {
      toast.error('Invalid fax number')
      return
    }

    const params = _.pickBy({
      physicianGroupId: groupId,
      name: `${firstName} ${lastName}`,
      firstName,
      lastName,
      email,
      phone,
      fax,
      address,
      address2,
      city,
      state,
      zip,
    })
    onSave(params)
  }

  return (
    <ListContainer className={className}>
      <div className='flex flex-row gap-4'>
        <ListField label='First Name' className='flex-1'>
          <Input
            placeholder='First Name'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </ListField>

        <ListField label='Last Name' className='flex-1'>
          <Input
            placeholder='Surname'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </ListField>
      </div>

      <ListField label='Physician Group'>
        <PhysicianGroupSelect
          selectedGroupID={groupId ?? -1}
          onChange={(group) => setGroupId(group?.physicianGroupId ?? -1)}
        />
      </ListField>

      <ListField label='Email'>
        <Input value={email} onChange={(e) => setEmail(e.target.value)} />
      </ListField>

      <ListField label='Phone'>
        <PhoneNumberInput value={phone} onChange={(phone: string) => setPhone(phone)} />
      </ListField>

      <ListField label='Fax'>
        <PhoneNumberInput value={fax} onChange={(fax: string) => setFax(fax)} />
      </ListField>

      <ListField label='Address'>
        <AddressInput
          value={address}
          onChange={(address: string) => {
            const [addr1, addr2, city, state, zip] = address.split(', ')
            setAddress(addr1)
            setAddress2(addr2)
            setCity(city)
            setState(state)
            setZip(zip)
          }}
        />
      </ListField>

      <div className='flex flex-row gap-4 pt-3 mx-2'>
        <Button variant='outline' className='flex-1' onClick={handleSave}>
          Save
        </Button>
        <Button variant='outline' className='flex-1' onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </ListContainer>
  )
}
