import { cn } from '@/lib/utils'
import { GalleryVerticalEnd, MessageCircle, Paperclip } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

type MetricSection = {
  icon: typeof GalleryVerticalEnd
  count: number
  label: string
  bgColor: string
  onClick: (e: React.MouseEvent) => void
}

export const StudyMetrics = ({
  scans = 0,
  attachments = 0,
  messages = 0,
  worklistId,
}: {
  scans: number | null
  attachments: number | null
  messages: number | null
  worklistId: number
}) => {
  const navigate = useNavigate()
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const sections: MetricSection[] = [
    {
      icon: GalleryVerticalEnd,
      count: scans ?? 0,
      label: 'scans',
      bgColor: 'bg-indigo-100',
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation()
        navigate(`/studies/${worklistId}/viewer`)
      },
    },
    {
      icon: Paperclip,
      count: attachments ?? 0,
      label: 'attachments',
      bgColor: 'bg-indigo-200',
      onClick: (e: React.MouseEvent) => e.stopPropagation(),
    },
    {
      icon: MessageCircle,
      count: messages ?? 0,
      label: 'messages',
      bgColor: 'bg-indigo-300',
      onClick: (e: React.MouseEvent) => e.stopPropagation(),
    },
  ]

  return (
    <div className='flex flex-col h-[80px] w-[160px] rounded-lg overflow-hidden'>
      {sections.map((section, index) => {
        const Icon = section.icon
        const isHovered = hoveredIndex === index

        return (
          <div
            key={index}
            className={cn(
              'flex items-center justify-center gap-2 transition-all duration-200 px-2',
              'cursor-pointer select-none',
              section.bgColor,
              isHovered ? 'flex-[3]' : 'flex-1',
              hoveredIndex !== null && !isHovered && 'flex-[0.5] px-0'
            )}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            onClick={section.onClick}>
            {!isHovered && hoveredIndex === null && <Icon className='w-4 h-4 shrink-0' />}
            {isHovered && (
              <span className='text-sm whitespace-nowrap'>
                {section.count} {section.label}
              </span>
            )}
          </div>
        )
      })}
    </div>
  )
}
