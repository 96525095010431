import { Button } from '@/components/ui/Button'
import { humanReadableDateAndYear } from '@/lib/dates'
import { updatePhysician } from '@/queries/physician'
import {
  useFetchStudy,
  useFetchStudyDicoms,
  useGetNewAttachmentURL,
  usePostNewAttachment,
  useUpdateStudy,
} from '@/queries/studies'
import { useAuthStore } from '@/state/authState'
import _ from 'lodash'
import { Loader2 } from 'lucide-react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiPhysician, ApiStudy } from 'shared'
import { SidePanel } from '../SidePanel'
import { EditStudyDetails } from './EditStudyDetails'
import { ExtraDetails } from './ExtraDetails'
import { StudyOverview } from './StudyOverview'

export const StudyDetailPanel = ({ className }: { className?: string }) => {
  const navigate = useNavigate()
  const params = useParams()
  const worklistId = parseInt(params.worklistId!)
  const { data: dicoms } = useFetchStudyDicoms(Number(worklistId))
  const { role } = useAuthStore()
  const isAdmin = useAuthStore((state) => state.role === 'admin')
  const isFacility = useAuthStore((state) => state.role === 'facility')
  const { isLoading, data: study, error } = useFetchStudy(worklistId)
  const { mutateAsync: updateWorklistItem } = useUpdateStudy(worklistId)
  const { mutateAsync: getAttachmentUrl } = useGetNewAttachmentURL(worklistId)
  const { mutateAsync: postAttachment } = usePostNewAttachment(worklistId)
  const [isEditing, setIsEditing] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const [doEdit, setDoEdit] = useState(false)

  // Reset isEditing when the worklistId changes (new study)
  useEffect(() => setIsEditing(false), [worklistId])

  // Set isPending when the study status changes
  useEffect(() => setIsPending(study?.status === 'PENDING'), [study])

  // When isPending is true, we want to set isEditing to true.
  // This is a meta-state variable that is always true when isPending is true,
  // or else follows the value of doEdit.
  useEffect(
    () => setIsEditing(role !== 'physician-group' && isPending ? true : doEdit),
    [isPending, doEdit, role]
  )

  const handleUpload = async (attachment: File) => {
    const url = await getAttachmentUrl(attachment!.name)
    await fetch(url, {
      method: 'PUT',
      body: attachment,
    })
    await postAttachment(url)
  }

  const doUpdateWorklistItem = async (
    data: Partial<ApiPhysician> & Partial<ApiStudy> & { attachment?: File | null }
  ) => {
    const referringPhysicianId = data.referringPhysicianId ?? study?.referringPhysicianId
    try {
      const { physicianGroupId, fax, attachment, ...studyData } = data
      await Promise.all([
        ...[
          (physicianGroupId || fax) &&
            updatePhysician(referringPhysicianId!, _.pickBy({ physicianGroupId, fax })!),
        ],
        ...[attachment && handleUpload(attachment)],
      ]).then(() => updateWorklistItem(studyData))
    } finally {
      setDoEdit(false)
    }
  }

  if (isLoading) {
    return (
      <div className='italic flex flex-row text-xl gap-2'>
        <Loader2 className='animate-spin' /> Loading Study...
      </div>
    )
  }

  if (error || !study) {
    return (
      <div className='text-red-600 p-4'>
        <h2 className='text-xl font-bold mb-2'>Error Loading Study</h2>
        <p>{error?.message || 'Study not found'}</p>
      </div>
    )
  }

  const studyDate = moment(study.studyDatetime)
  const { modalityProcedure } = study

  return (
    <SidePanel
      label={modalityProcedure ?? 'No Description'}
      description={humanReadableDateAndYear(studyDate.toDate())}
      canEdit={(isAdmin || isFacility) && !isEditing}
      onEdit={() => setDoEdit(true)}>
      {isPending && (
        <div className='border-2 rounded-lg bg-yellow-50 border-yellow-200 text-yellow-900 p-4 my-4'>
          This study is <strong>pending</strong>. Fill in the details below to complete the study.
        </div>
      )}

      <Button
        className='w-full mx-2'
        variant='outline'
        onClick={() => navigate(`/studies/${worklistId!}/viewer`)}
        disabled={!_.isArray(dicoms) || dicoms.length === 0}>
        View Images
      </Button>

      <div className='flex-1 my-4 space-y-2'>
        {isEditing ? (
          <EditStudyDetails
            selectedStudy={study!}
            onSave={doUpdateWorklistItem}
            onCancel={() => setDoEdit(false)}
          />
        ) : (
          <StudyOverview study={study!} navigate={navigate} dicoms={dicoms!} />
        )}
      </div>

      <div className='border-t pt-3 mt-12'>
        <ExtraDetails worklistId={worklistId} />
      </div>
    </SidePanel>
  )
}
