import { Button } from '@/components/ui/Button'
import { ListContainer, ListField } from '@/components/ui/List'
import { TabBrowser } from '@/components/ui/TabView'
import { useUpdateStudy } from '@/queries/studies'
import { useAuthStore } from '@/state/authState'
import _ from 'lodash'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ApiDicomSeries, ApiStudy } from 'shared'
import voca from 'voca'
import { AttachmentsTable } from './AttachmentsTable'
import { DicomStudyTable } from './DicomStudyTable'
import { DownloadReport } from './DownloadReport'
import { WorklistMessages } from './WorklistMessages'

export const StudyOverview = ({
  study,
  navigate,
  dicoms,
}: {
  study: ApiStudy
  navigate: (path: string) => void
  dicoms: ApiDicomSeries[]
}) => {
  const { worklistId } = study
  const [currentTab, setCurrentTab] = useState<'images' | 'files' | 'memos'>('images')
  const { mutateAsync: updateStudy } = useUpdateStudy(Number(worklistId))
  const role = useAuthStore((u) => u.role)
  const { status } = study

  return (
    <div>
      <ListContainer>
        <ListField label='Patient' labelClass='w-[140px]'>
          <Link
            to={`/patients/${study.patientId}`}
            className='text-indigo-700 text-md hover:text-indigo-500 font-medium'>
            {voca.titleCase(study.patientName ?? 'N/A')}
          </Link>
        </ListField>

        <ListField label='Physician' labelClass='w-[140px]'>
          <div>
            <Link
              to={`/physicians/${study.referringPhysicianId}`}
              className='text-indigo-700 text-md hover:text-indigo-500 font-medium'>
              {voca.titleCase(study.referringPhysician?.name ?? 'N/A')}
            </Link>
          </div>
        </ListField>

        <ListField label='Physician Group' labelClass='w-[140px]'>
          <div>
            <Link
              to={`/physicians/${study.referringPhysicianId}`}
              className='text-indigo-700 text-md hover:text-indigo-500 font-medium'>
              {voca.titleCase(study.referringPhysician?.physicianGroup ?? 'N/A')}
            </Link>
          </div>
        </ListField>

        <ListField label='Physician Fax' labelClass='w-[140px]'>
          {study.referringPhysician?.fax ?? 'N/A'}
        </ListField>

        {study.reason && (
          <ListField label='Clinical History' labelClass='w-[140px]'>
            {voca.capitalize(study.reason)}
          </ListField>
        )}
      </ListContainer>

      <div className='py-4 flex flex-col gap-2'>
        {role !== 'physician-group' && (
          <>
            <Button
              className={`w-full mx-2 border-red-200 text-red-900 hover:text-red-900 hover:bg-red-50 ${
                study.priority === 'STAT' ? 'bg-red-50' : ''
              }`}
              variant='outline'
              onClick={() =>
                updateStudy({ priority: study.priority === 'STAT' ? 'ROUTINE' : 'STAT' })
              }>
              {study.priority === 'STAT' ? 'Unmark as STAT' : 'Mark as STAT'}
            </Button>
          </>
        )}

        <Button
          className='w-full mx-2'
          variant='outline'
          onClick={() => navigate(`/studies/${worklistId!}/viewer`)}
          disabled={!_.isArray(dicoms) || dicoms.length === 0}>
          View Images
        </Button>

        {/^Reported/.test(status ?? 'Unknown') && study.reportUrl && (
          <DownloadReport url={study.reportUrl} />
        )}
      </div>

      <div>
        <TabBrowser
          tabs={['images', 'files', 'memos']}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />

        <div className='pt-2'>
          {currentTab === 'images' ? (
            <DicomStudyTable worklistId={Number(worklistId)} />
          ) : currentTab === 'files' ? (
            <AttachmentsTable worklistId={Number(worklistId)} />
          ) : (
            currentTab === 'memos' && <WorklistMessages worklistId={Number(worklistId)} />
          )}
        </div>
      </div>
    </div>
  )
}
