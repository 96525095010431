import { StatusIcon, StudyStatus } from '@/components/ui/Status'
import {
  StudyTableCell,
  StudyTableRow,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/Table'
import { humanReadableDate } from '@/lib/dates'
import { cn } from '@/lib/utils'
import { useAuthStore } from '@/state/authState'
import { useGlobalState } from '@/state/globalState'
import moment from 'moment-timezone'
import { RefObject } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApiStudy } from 'shared'
import voca from 'voca'
import { PriorityBadge } from './PriorityBadge'
import { StudyMetrics } from './StudyMetrics'

const StudiesTable = ({
  studies,
  selectedStudyId,
  selectedStudyRowRef,
}: {
  studies: ApiStudy[]
  selectedStudyId: number
  selectedStudyRowRef: RefObject<HTMLTableRowElement>
}) => {
  const { role } = useAuthStore.getState()

  if (studies.length === 0) {
    return <div className='text-lg text-neutral-500 text-center'>No studies found</div>
  }

  return (
    <Table className='mobile-table overflow-x-auto' variant='studies'>
      <TableHeader>
        <TableRow>
          <TableHead />
          <TableHead>Date</TableHead>
          {role === 'admin' && <TableHead>Facility</TableHead>}
          <TableHead>Priority</TableHead>
          <TableHead>Patient</TableHead>
          <TableHead className='text-center'>Modality</TableHead>
          <TableHead>Description</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {studies.map((study) => (
          <DataRow
            key={study.studyUid}
            study={study}
            selected={selectedStudyId === study.worklistId}
            selectedStudyRowRef={selectedStudyRowRef}
            status={(() => {
              switch (role) {
                case 'physician-group':
                  return study.priority
                default:
                  return study.status
              }
            })()}
          />
        ))}
      </TableBody>
    </Table>
  )
}

const DataRow = ({
  study,
  selected,
  selectedStudyRowRef,
  status,
}: {
  study: ApiStudy
  selected: boolean
  selectedStudyRowRef?: RefObject<HTMLTableRowElement>
  status: string | null
}) => {
  const navigate = useNavigate()
  const mouseIsDown = useGlobalState((s) => s.mouseIsDown)
  const studyDate = moment(study.studyDatetime)
  const role = useAuthStore((u) => u.role)

  let bg = selected ? 'bg-neutral-100 hover:bg-neutral-200' : ''

  return (
    <StudyTableRow
      className={cn(mouseIsDown && 'select-none', bg)}
      onClick={(event: any) => {
        event.preventDefault()
        navigate('/studies/' + study.worklistId)
      }}
      ref={selected ? selectedStudyRowRef : undefined}>
      <StudyTableCell
        className='w-[40px] whitespace-nowrap'
        tooltip={`${voca.titleCase(status ?? '')}`}
        data-cell='status'>
        <StatusIcon className='ml-2 w-4 h-4 text-neutral-500' status={status as StudyStatus} />
      </StudyTableCell>
      <StudyTableCell className='w-[80px] text-left whitespace-nowrap' data-cell='date'>
        {studyDate.isSame(moment(), 'day')
          ? studyDate.format('h:mm A')
          : humanReadableDate(studyDate.toDate())}
      </StudyTableCell>
      {role === 'admin' && (
        <StudyTableCell
          className='text-left max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis'
          data-cell='facility'>
          {study.facilityName}
        </StudyTableCell>
      )}
      <StudyTableCell className='whitespace-nowrap' data-cell='priority'>
        <PriorityBadge
          priority={study.priority}
          studyDatetime={study.studyDatetime?.toString() ?? ''}
        />
      </StudyTableCell>
      <StudyTableCell
        className='text-left max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis'
        data-cell='patient'>
        {voca.titleCase(study.patientName ?? 'N/A')}
      </StudyTableCell>
      <StudyTableCell className='w-[40px] text-center whitespace-nowrap' data-cell='class'>
        {study.modalityCode}
      </StudyTableCell>
      <StudyTableCell
        className='text-left max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis'
        data-cell='procedure'>
        {study.modalityProcedure ?? <div className='italic font-light'>No Description</div>}
      </StudyTableCell>
      <StudyTableCell className='w-[160px]' data-cell='metrics'>
        <StudyMetrics
          scans={study.seriesCount}
          attachments={study.documentsCount}
          messages={study.messagesCount}
          worklistId={study.worklistId!}
        />
      </StudyTableCell>
    </StudyTableRow>
  )
}

export default StudiesTable
