import { LoadingSpinner } from '@/components/LoadingSpinner'
import { PanelGroup } from '@/components/PanelGroup'
import { NewPhysicianGroup } from '@/components/PhysGroupDetailView/NewPhysicianGroup'
import { PhysGroupDetail } from '@/components/PhysGroupDetailView/PhysGroupDetailView'
import { NewPhysician } from '@/components/PhysicianDetailView/NewPhysician'
import { PhysicianDetail } from '@/components/PhysicianDetailView/PhysicianDetailView'
import { PhysicianGroupTable } from '@/components/PhysiciansPage/PhysicianGroupTable'
import { Button } from '@/components/ui/Button'
import { Input } from '@/components/ui/Input'
import { useFetchPhysicianGroups, useFetchZombiePhysicians } from '@/queries/physicianGroup'
import { useAuthStore } from '@/state/authState'
import _ from 'lodash'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiPhysicianGroup } from 'shared'

const PhysiciansPage = (props: { category: 'physician' | 'group' }) => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const isAdmin = useAuthStore((state) => state.role === 'admin')
  const isFacility = useAuthStore((state) => state.role === 'facility')
  let { isLoading, data: groups } = useFetchPhysicianGroups()
  const { data: zombies } = useFetchZombiePhysicians()
  const [searchText, setSearchText] = useState('')

  if (isLoading) {
    return (
      <div className='flex justify-center w-full mt-16'>
        <LoadingSpinner />
      </div>
    )
  }

  if (_.isArray(zombies) && zombies.length > 0) {
    groups = [
      ...(groups ?? []),
      {
        physicianGroupId: -1,
        groupName: 'Unassigned',
        facilityId: -1,
        facility: 'Unassigned',
        email: null,
        phone: null,
        fax: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        physicians: zombies ?? [],
      },
    ]
  }

  if (searchText.length > 0) {
    groups = _.reduce(
      groups,
      (acc: ApiPhysicianGroup[], grp: ApiPhysicianGroup) => {
        // Search for matching group name
        if (
          _.every(
            searchText.toLowerCase().split(' '),
            (p) => grp.groupName?.toLowerCase().includes(p) ?? false
          )
        ) {
          acc.push(grp)
        } else {
          // Search for matching physicians by name
          const matchPhys = _.filter(grp.physicians, (phys) =>
            _.every(
              searchText.toLowerCase().split(' '),
              (p) => phys.name?.toLowerCase().includes(p) ?? false
            )
          )
          if (matchPhys.length > 0) {
            acc.push({ ...grp, physicians: matchPhys })
          }
        }

        return acc
      },
      []
    )
  }

  return (
    <div className='flex flex-col h-full w-full'>
      <div className='flex justify-between space-x-2 mt-6 mb-4'>
        <Input
          placeholder='Search Physicians'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className='border-neutral-100 shadow-sm'
        />
        {(isAdmin || isFacility) && (
          <Button
            variant='ghost'
            className='font-normal hover:bg-neutral-100'
            onClick={() => {
              navigate('/groups/new')
            }}>
            New Group
          </Button>
        )}
      </div>

      <PanelGroup
        parent={
          <PhysicianGroupTable
            groups={groups ?? []}
            selectedPhysicianID={props.category === 'physician' && id ? parseInt(id) : undefined}
            selectedGroupID={props.category === 'group' && id ? parseInt(id) : undefined}
          />
        }>
        {id &&
          (props.category === 'group' ? (
            id === 'new' ? (
              <NewPhysicianGroup
                onSave={async (group) => {
                  const { physicianGroupId } = group
                  navigate(`/groups/${physicianGroupId}`)
                }}
                onCancel={() => navigate('/groups')}
              />
            ) : (
              <PhysGroupDetail groupId={parseInt(id ?? '')} />
            )
          ) : id === 'new' ? (
            <NewPhysician
              onSave={async (physician) => {
                const { physicianId } = physician
                navigate(`/physicians/${physicianId}`)
              }}
              onCancel={() => navigate('/physicians')}
            />
          ) : (
            <PhysicianDetail physicianId={parseInt(id ?? '')} />
          ))}
      </PanelGroup>
    </div>
  )
}

export default PhysiciansPage
