import { StatusBubble } from '@/components/ui/Status'
import voca from 'voca'
import { TimerBadge } from './TimerBadge'

export const PriorityBadge = ({
  priority,
  studyDatetime,
}: {
  priority: string | null
  studyDatetime: string
}) => {
  const isStat = priority === 'STAT'
  return (
    <div className='flex flex-col gap-1'>
      <div className='flex items-center gap-2 bg-white border border-neutral-200 border-opacity-75 rounded-lg py-1 px-3 w-fit'>
        <StatusBubble color={isStat ? 'red' : 'green'} />
        <span>{priority?.toUpperCase() === 'STAT' ? 'STAT' : voca.titleCase(priority ?? '')}</span>
      </div>
      {isStat && <TimerBadge studyDatetime={studyDatetime} />}
    </div>
  )
}
