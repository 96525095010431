import { Select, SelectContent, SelectTrigger, SelectValue } from '@/components/ui/Select'
import { useFetchFacilities } from '@/queries/facility'
import { SelectItem } from '@radix-ui/react-select'
import voca from 'voca'

export const FacilitySelect = ({
  selectedFacilityID,
  onChange,
}: {
  selectedFacilityID: number | null
  onChange: (facilityId: number) => void
}) => {
  const { isLoading, data: facilities } = useFetchFacilities()

  if (isLoading) {
    return <div>Loading...</div>
  }

  const selectedFacility = facilities?.find((f) => f.facilityId === selectedFacilityID)
  const sortedFacilities = facilities?.sort((f1, f2) => f1.name!.localeCompare(f2.name!))

  return (
    <Select
      value={`${selectedFacilityID}` ?? undefined}
      onValueChange={(id: any) => onChange(parseInt(id))}>
      <SelectTrigger>
        <SelectValue className='bg-red-100' placeholder='Select Group...'>
          {selectedFacility ? (
            voca.titleCase(selectedFacility.name!)
          ) : (
            <div className='italic'>+ Assign</div>
          )}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {sortedFacilities?.map((f) => (
          <SelectItem key={f.facilityId} value={`${f.facilityId}`}>
            {voca.titleCase(f.name ?? 'Unknown')}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
