import { PhysicianGroupSelect } from '@/components/PhysGroupSelect/PhysicianGroupSelect'
import { PhysicianComboBox } from '@/components/PhysiciansComboBox/PhysiciansComboBox'
import { ModalitiesChart } from '@/components/ReportsPage/ModalitiesChart'
import { TopReferrersChart } from '@/components/ReportsPage/TopReferrersChart'
import { NumberInput } from '@/components/ui/Input'
import { ListFieldStacked } from '@/components/ui/List'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/Select'
import React, { useEffect } from 'react'
import { ApiPhysician, ApiPhysicianGroup, ReportFilter, ReportInterval, ReportType } from 'shared'

const ReportsPage = () => {
  const [chartType, setChartType] = React.useState<ReportType>('by-modality')
  const [filterType, setFilterType] = React.useState<ReportFilter>('overall')
  const [interval, setInterval] = React.useState<ReportInterval>('daily')
  const [periods, setPeriods] = React.useState<number>(6)
  const [physician, setPhysician] = React.useState<ApiPhysician | undefined>()
  const [physicianGroup, setPhysicianGroup] = React.useState<ApiPhysicianGroup | undefined>()
  const [numReferrers, setNumReferrers] = React.useState<number>(5)

  useEffect(() => {
    setPhysician(undefined)
    setPhysicianGroup(undefined)
  }, [filterType])

  useEffect(() => {
    if (chartType === 'top-referrers') setNumReferrers(5)
  }, [chartType])

  useEffect(() => {
    if (interval === 'daily') setPeriods(14)
    else if (interval === 'monthly') setPeriods(6)
    else if (interval === 'yearly') setPeriods(3)
  }, [interval])

  return (
    <div dir='rtl' className='w-full h-full flex gap-8 self-center px-8 pb-6'>
      <div dir='ltr' className='min-w-[16em] my-8 flex flex-col gap-2 justify-between'>
        <div>
          <ListFieldStacked label='Chart Type'>
            <Select value={chartType} onValueChange={(v: any) => setChartType(v)}>
              <SelectTrigger>
                <SelectValue placeholder='Select Type' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='by-modality'>By Modality</SelectItem>
                <SelectItem value='top-referrers'>Top Referrers</SelectItem>
              </SelectContent>
            </Select>
          </ListFieldStacked>

          {chartType === 'by-modality' && (
            <>
              <ListFieldStacked label='Filter By'>
                <Select value={filterType} onValueChange={(v: any) => setFilterType(v)}>
                  <SelectTrigger>
                    <SelectValue placeholder='Select Filter Type' />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value='overall'>Aggregate (None)</SelectItem>
                    <SelectItem value='by-physician-group'>Physician Group</SelectItem>
                    <SelectItem value='by-physician'>Referring Physician</SelectItem>
                    {/*<SelectItem value="by-radiologist">Radiologist</SelectItem>*/}
                  </SelectContent>
                </Select>
              </ListFieldStacked>

              {filterType === 'by-physician-group' ? (
                <ListFieldStacked label='Physician Group'>
                  <PhysicianGroupSelect
                    selectedGroupID={physicianGroup?.physicianGroupId}
                    onChange={(group) => setPhysicianGroup(group)}
                  />
                </ListFieldStacked>
              ) : filterType === 'by-physician' ? (
                <ListFieldStacked label='Physician'>
                  <PhysicianComboBox
                    physician={physician!}
                    onChange={(physician) => setPhysician(physician)}
                  />
                </ListFieldStacked>
              ) : null}
            </>
          )}

          {chartType === 'top-referrers' && (
            <ListFieldStacked label='Number of Physicians'>
              <NumberInput value={numReferrers} onValueChange={setNumReferrers} min={1} max={100} />
            </ListFieldStacked>
          )}
        </div>

        <div>
          <ListFieldStacked label='Interval'>
            <Select value={interval} onValueChange={(v: any) => setInterval(v)}>
              <SelectTrigger>
                <SelectValue placeholder='Select Interval' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='daily'>Daily</SelectItem>
                <SelectItem value='monthly'>Monthly</SelectItem>
                <SelectItem value='yearly'>Yearly</SelectItem>
              </SelectContent>
            </Select>
          </ListFieldStacked>

          <ListFieldStacked
            label={`${
              interval === 'daily'
                ? 'Days'
                : interval === 'monthly'
                ? 'Months'
                : interval === 'yearly'
                ? 'Years'
                : 'Periods'
            }`}>
            <NumberInput value={periods} onValueChange={setPeriods} min={1} max={100} />
          </ListFieldStacked>
        </div>
      </div>

      <div className='flex-1 h-full pt-2'>
        {chartType === 'by-modality' ? (
          <ModalitiesChart
            interval={interval}
            periods={periods}
            filterType={filterType}
            filterId={
              filterType === 'by-physician'
                ? physician?.physicianId
                : filterType === 'by-physician-group'
                ? physicianGroup?.physicianGroupId
                : undefined
            }
          />
        ) : (
          chartType === 'top-referrers' && (
            <TopReferrersChart interval={interval} periods={periods} numReferrers={numReferrers} />
          )
        )}
      </div>
    </div>
  )
}

export default ReportsPage
