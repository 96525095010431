import { UserRole } from 'shared'

export const formatRole = (role: UserRole) => {
  switch (role) {
    case 'admin':
      return 'Admin'
    case 'radiologist':
      return 'Radiologist'
    case 'physician-group':
      return 'Physician Group'
    case 'facility':
      return 'Facility'
    default:
      return 'Unknown'
  }
}
