import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/Table'
import { humanReadableDate } from '@/lib/dates'
import { useNavigate } from 'react-router-dom'
import { ApiStudy } from 'shared'
import voca from 'voca'

export const PhysStudiesTable = ({ studies }: { studies: ApiStudy[] }) => {
  const navigate = useNavigate()

  if (!studies || studies.length === 0) {
    return <div>No studies</div>
  }

  return (
    <div>
      <Table className='px-1'>
        <TableHeader>
          <TableRow>
            <TableHead>Patient</TableHead>
            <TableHead>M.</TableHead>
            <TableHead>Procedure</TableHead>
            <TableHead>Timestamp</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {studies.map((study) => (
            <TableRow key={study.studyUid} onClick={() => navigate(`/studies/${study.worklistId}`)}>
              <TableCell>{voca.titleCase(study.patientName ?? 'N/A')}</TableCell>
              <TableCell>{study.modalityCode}</TableCell>
              <TableCell>{voca.capitalize(study.modalityProcedure ?? 'N/A')}</TableCell>
              <TableCell>
                {study.studyDatetime ? humanReadableDate(study.studyDatetime) : 'N/A'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default PhysStudiesTable
