import { LoadingSpinner } from '@/components/LoadingSpinner'
import { PanelGroup } from '@/components/PanelGroup'
import { PatientsTable } from '@/components/PatientsPage/PatientsTable'
import { Input } from '@/components/ui/Input'
import { useSearchPatients } from '@/queries/patient'
import { useQueryClient } from '@tanstack/react-query'
import dateParser from 'any-date-parser'
import _ from 'lodash'
import type { ComponentType } from 'react'
import React, { useEffect, useMemo, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router-dom'
import PatientDetailView from '../components/PatientDetailView/PatientDetailView'

const InfiniteScrollComponent = InfiniteScroll as unknown as ComponentType<any>

const PatientsPage = () => {
  const params = useParams<{ patientId: string }>()
  const selectedPatientId = Number(params.patientId)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [searchText, setSearchText] = React.useState('')

  const searchQuery = useMemo(() => {
    // Year only (parser gets confused by this)
    if (/^\d{4}$/.test(searchText)) return `${searchText}-`
    const dateStr = dateParser.attempt(searchText)
    const { month, day, year } = dateStr
    if (year && month && day) {
      return `${year}-${_.padStart(month, 2, '0')}-${_.padStart(day, 2, '0')}`
    } else if (month && day) {
      return `-${_.padStart(month, 2, '0')}-${_.padStart(day, 2, '0')}`
    }
    return searchText
  }, [searchText])

  const query = useSearchPatients({ searchText: searchQuery })

  useEffect(() => {
    // fetch more if content does not fill page
    const handleFetchMore = () => {
      if (!query.hasNextPage) return
      const container = scrollRef.current
      if (container && container.scrollHeight <= container.clientHeight) {
        query.fetchNextPage()
      }
    }
    _.throttle(handleFetchMore, 500, { leading: false })()
  }, [query])

  const queryClient = useQueryClient()
  const updateSearchText = (searchText: string) => {
    setSearchText(searchText)
    queryClient.removeQueries({ queryKey: ['patients'] })
  }

  const patients = useMemo(() => _.flatten(query?.data?.pages), [query.data])

  return (
    <div className='flex flex-col w-full items-center h-full'>
      <Input
        value={searchText}
        onChange={(e) => updateSearchText(e.target.value)}
        autoCapitalize='false'
        placeholder='Search Patients'
        className='mt-6 mb-4 border-neutral-100 shadow-sm'
      />

      <PanelGroup
        parent={
          <InfiniteScrollComponent
            dataLength={patients.length}
            next={query.fetchNextPage}
            hasMore={query.hasNextPage}
            refreshFunction={query.refetch}
            pullDownToRefresh={true}
            pullDownToRefreshThreshold={50}
            releaseToRefreshContent={<h3 style={{ textAlign: 'center' }}>Release to refresh</h3>}
            scrollableTarget='scrollablePatientsDiv'
            loader={
              query.isLoading && (
                <div className='flex justify-center w-full'>
                  <LoadingSpinner />
                </div>
              )
            }>
            {query.isLoading ? (
              <div className='flex justify-center w-full'>
                <LoadingSpinner />
              </div>
            ) : (
              <PatientsTable patients={patients} selectedPatientId={selectedPatientId} />
            )}
          </InfiniteScrollComponent>
        }>
        {!!selectedPatientId && !isNaN(selectedPatientId) && (
          <PatientDetailView patientId={selectedPatientId} />
        )}
      </PanelGroup>
    </div>
  )
}

export default PatientsPage
