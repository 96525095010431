import { FacilitiesTable } from '@/components/FacilitiesPage/FacilitiesTable'
import { FacilityDetails } from '@/components/FacilitiesPage/FacilityDetails'
import { NewFacilityView } from '@/components/FacilitiesPage/NewFacilityView'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import { PanelGroup } from '@/components/PanelGroup'
import { Button } from '@/components/ui/Button'
import { Input } from '@/components/ui/Input'
import { useFetchFacilities } from '@/queries/facility'
import _ from 'lodash'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiFacility } from 'shared'

const FacilitiesView = () => {
  const params = useParams()
  const navigate = useNavigate()
  const selectedFacilityId = parseInt(params.id ?? '0')
  const [searchText, setSearchText] = React.useState('')
  let { isLoading, data: facilities } = useFetchFacilities()
  const isNewFacility = params.id === 'new'

  if (isLoading) {
    return (
      <div className='flex justify-center w-full mt-16'>
        <LoadingSpinner />
      </div>
    )
  }

  if (searchText.length > 0) {
    facilities = _.reduce(
      facilities,
      (acc: ApiFacility[], fac: ApiFacility) => {
        // Search for matching group name
        if (
          _.every(
            searchText.toLowerCase().split(' '),
            (p) => fac.name?.toLowerCase().includes(p) ?? false
          )
        ) {
          acc.push(fac)
        }

        return acc
      },
      []
    )
  }

  return (
    <div className='flex flex-col w-full items-center h-full'>
      <div className='w-full flex justify-between space-x-2 mt-6 mb-4'>
        <Input
          placeholder='Search Facilities'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className='border-neutral-100 shadow-sm'
        />

        <Button
          variant='ghost'
          className='font-normal hover:bg-neutral-100'
          onClick={() => {
            navigate('/facilities/new')
          }}>
          New Facility
        </Button>
      </div>

      <PanelGroup parent={<FacilitiesTable facilities={facilities} />}>
        {params.id &&
          (isNewFacility ? (
            <NewFacilityView
              onSave={(facility: ApiFacility) => navigate(`/facilities/${facility.facilityId}`)}
              onCancel={() => navigate('/facilities')}
            />
          ) : (
            <FacilityDetails facilityId={selectedFacilityId} />
          ))}
      </PanelGroup>
    </div>
  )
}

export default FacilitiesView
