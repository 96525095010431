import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/Table'
import { humanReadableDate } from '@/lib/dates'
import { useNavigate } from 'react-router-dom'
import { ApiPatient } from 'shared'
import voca from 'voca'

export const PatientsTable = ({
  patients,
  selectedPatientId,
}: {
  patients: ApiPatient[]
  selectedPatientId?: number
}) => {
  const navigate = useNavigate()

  if (patients.length === 0) {
    return <div className='text-lg italic text-center'>No patients to display</div>
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead>MRN</TableHead>
          <TableHead className='text-center'>Date of Birth</TableHead>
        </TableRow>
      </TableHeader>

      <TableBody>
        {patients.map((patient) => {
          let { firstName, lastName, name } = patient
          if (firstName && lastName) name = `${lastName}, ${firstName}`
          return (
            <TableRow
              key={patient.patientId}
              className={`transition-all duration-25 ${
                selectedPatientId === patient.patientId && 'bg-neutral-100 hover:bg-neutral-200'
              }`}
              onClick={() => navigate(`/patients/${patient.patientId}`)}>
              <TableCell>{voca.titleCase(name ?? 'Unknown Patient')}</TableCell>
              <TableCell>{patient.mrn}</TableCell>
              <TableCell className='text-center'>
                {patient.birthDate ? humanReadableDate(new Date(patient.birthDate)) : 'N/A'}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
