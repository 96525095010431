import { useFetchPatient, useUpdatePatient } from '@/queries/patient'
import { useAuthStore } from '@/state/authState'
import { useState } from 'react'
import { ApiPatient } from 'shared'
import { SidePanel } from '../SidePanel'
import { EditPatientInfo } from './EditPatientInfo'
import { PatientInfo } from './PatientInfo'
import { PatientStudies } from './PatientStudies'
import { Loader2 } from 'lucide-react'

const PatientDetail = ({ patientId }: { patientId: number }) => {
  const isAdmin = useAuthStore((state) => state.role === 'admin')
  const isFacility = useAuthStore((state) => state.role === 'facility')
  const [isEditing, setIsEditing] = useState(false)
  const query = useFetchPatient(patientId)
  const patient: ApiPatient | undefined = query.data
  const updatePatient = useUpdatePatient(patientId)

  if (!patient) {
    if (query.isLoading) {
      return (
        <div className='flex justify-center mt-4 h-full'>
          <Loader2 className='animate-spin mr-4' /> Loading Patient...
        </div>
      )
    }

    if (query.isError) {
      return (
        <div className='text-red-600 p-4'>
          <h2 className='text-xl font-bold mb-2'>Error Loading Patient</h2>
          <p>{query.error.message}</p>
        </div>
      )
    }

    return (
      <div className='text-red-600 p-4'>
        <h2 className='text-xl font-bold mb-2'>Error Loading Patient</h2>
        <p>Patient not found</p>
      </div>
    )
  }

  let { name, firstName, lastName } = patient
  if (!!firstName && !!lastName) {
    name = `${firstName} ${lastName}`
  } else if (!name) {
    name = 'Unknown'
  }

  return (
    <SidePanel
      label={name}
      description={`MRN: ${patient!.mrn}`}
      canEdit={(isAdmin || isFacility) && !isEditing}
      onEdit={() => setIsEditing(true)}>
      {isEditing ? (
        <EditPatientInfo
          patient={patient}
          onSave={async (data) => {
            await updatePatient.mutateAsync(data)
            await query.refetch()
            setIsEditing(false)
          }}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <>
          <PatientInfo patient={patient} />
          <div className='text-lg font-bold mt-8 mb-2 border-b border-neutral-200 text-neutral-600'>
            Studies:
          </div>
          <PatientStudies className='px-1' studies={patient.studies!} />
        </>
      )}
    </SidePanel>
  )
}

export default PatientDetail
