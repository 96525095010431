import { fetchFromApi } from '@/lib/fetchFromApi'
import { useQuery } from '@tanstack/react-query'
import { ReportFilter, ReportInterval } from 'shared'

export const useModalitiesReport = ({
  interval,
  periods,
  filterType,
  filterId,
}: {
  interval: ReportInterval
  periods: number
  filterType: ReportFilter
  filterId?: number
}) =>
  useQuery({
    queryKey: ['report', 'by-modality', interval, periods, filterType, filterId],
    queryFn: async () => {
      let endpointStem = `reports/by-modality?interval=${interval}&periods=${periods}`

      if (filterType === 'by-physician') {
        endpointStem += `&physicianIds=${filterId ?? -1}`
      } else if (filterType === 'by-physician-group') {
        endpointStem += `&physicianGroupIds=${filterId ?? -1}`
      } else if (filterType === 'by-radiologist') {
        endpointStem += `&readPhysicianIds=${filterId ?? -1}`
      }
      const res = await fetchFromApi(endpointStem)
      const data = (await res.json()) as any
      return data
    },
  })

export const useTopReferrersReport = ({
  interval,
  periods,
  referrers,
}: {
  interval: ReportInterval
  periods: number
  referrers?: number
}) =>
  useQuery({
    queryKey: ['report', 'top-referrers', interval, periods, referrers],
    queryFn: async () => {
      let endpointStem = `reports/top-referrers?interval=${interval}&periods=${
        periods ?? 12
      }&count=${referrers ?? 10}`
      const res = await fetchFromApi(endpointStem)
      const data = (await res.json()) as any
      return data
    },
  })
